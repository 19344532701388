export enum EventType {
  // Room state events
  RoomCanonicalAlias = "m.room.canonical_alias",
  RoomCreate = "m.room.create",
  RoomJoinRules = "m.room.join_rules",
  RoomMember = "m.room.member",
  RoomThirdPartyInvite = "m.room.third_party_invite",
  RoomPowerLevels = "m.room.power_levels",
  RoomName = "m.room.name",
  RoomTopic = "m.room.topic",
  RoomAvatar = "m.room.avatar",
  RoomPinnedEvents = "m.room.pinned_events",
  RoomEncryption = "m.room.encryption",
  RoomHistoryVisibility = "m.room.history_visibility",
  RoomGuestAccess = "m.room.guest_access",
  RoomServerAcl = "m.room.server_acl",
  RoomTombstone = "m.room.tombstone",
  RoomPredecessor = "org.matrix.msc3946.room_predecessor",

  SpaceChild = "m.space.child",
  SpaceParent = "m.space.parent",

  // Room timeline events
  RoomRedaction = "m.room.redaction",
  RoomMessage = "m.room.message",
  RoomMessageEncrypted = "m.room.encrypted",
  Sticker = "m.sticker",
  CallInvite = "m.call.invite",
  CallCandidates = "m.call.candidates",
  CallAnswer = "m.call.answer",
  CallHangup = "m.call.hangup",
  CallReject = "m.call.reject",
  CallSelectAnswer = "m.call.select_answer",
  CallNegotiate = "m.call.negotiate",
  CallSDPStreamMetadataChanged = "m.call.sdp_stream_metadata_changed",
  CallSDPStreamMetadataChangedPrefix = "org.matrix.call.sdp_stream_metadata_changed",
  CallReplaces = "m.call.replaces",
  CallAssertedIdentity = "m.call.asserted_identity",
  CallAssertedIdentityPrefix = "org.matrix.call.asserted_identity",
  KeyVerificationRequest = "m.key.verification.request",
  KeyVerificationStart = "m.key.verification.start",
  KeyVerificationCancel = "m.key.verification.cancel",
  KeyVerificationMac = "m.key.verification.mac",
  KeyVerificationDone = "m.key.verification.done",
  KeyVerificationKey = "m.key.verification.key",
  KeyVerificationAccept = "m.key.verification.accept",
  // Not used directly - see READY_TYPE in VerificationRequest.
  KeyVerificationReady = "m.key.verification.ready",
  // use of this is discouraged https://matrix.org/docs/spec/client_server/r0.6.1#m-room-message-feedback
  RoomMessageFeedback = "m.room.message.feedback",
  Reaction = "m.reaction",
  PollStart = "org.matrix.msc3381.poll.start",

  // Room ephemeral events
  Typing = "m.typing",
  Receipt = "m.receipt",
  Presence = "m.presence",

  // Room account_data events
  FullyRead = "m.fully_read",
  Tag = "m.tag",
  SpaceOrder = "org.matrix.msc3230.space_order", // MSC3230

  // User account_data events
  PushRules = "m.push_rules",
  Direct = "m.direct",
  IgnoredUserList = "m.ignored_user_list",

  // to_device events
  RoomKey = "m.room_key",
  RoomKeyRequest = "m.room_key_request",
  ForwardedRoomKey = "m.forwarded_room_key",
  Dummy = "m.dummy",

  // Group call events
  GroupCallPrefix = "org.matrix.msc3401.call",
  GroupCallMemberPrefix = "org.matrix.msc3401.call.member",
}

export enum RelationType {
  Annotation = "m.annotation",
  Replace = "m.replace",
  Reference = "m.reference",
  Thread = "m.thread",
}

export enum MsgType {
  Text = "m.text",
  Emote = "m.emote",
  Notice = "m.notice",
  Image = "m.image",
  File = "m.file",
  Audio = "m.audio",
  Location = "m.location",
  Video = "m.video",
  KeyVerificationRequest = "m.key.verification.request",
}
