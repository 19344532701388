// fetch remote config
interface IRemoteConfig {
  enable_confirm_group_invite: boolean;
  name: string; // 名称
  api_url: string; // 服务端地址
  explore_url: string; // 探索地址
  enable_share_location: boolean; // 启用位置发送
  enable_register_email: boolean; // 启用邮箱注册
  enable_video_call: boolean; // 启用语音通话
  default_friends: string[]; // 默认好友
  search_domain: string; //  id后缀
  android_download_url?: string;
  ios_download_url?: string;
  message_sending_interval?: number;
}

const initConfig: IRemoteConfig = {
  name: "IM",
  api_url: "",
  explore_url: "https://baidu.com",
  enable_share_location: false,
  enable_register_email: false,
  enable_video_call: false,
  default_friends: [],
  search_domain: "",
};
const IM_REMOTE_CONFIG = "IM_REMOTE_CONFIG";

export class KgUtils {
  private configUrl;
  public config: IRemoteConfig;
  public remote_config_urls = [];

  private static _instance: KgUtils;

  public fetchConfig = async () => {
    if (!this.configUrl) return;

    try {
      const res = await fetch(this.configUrl);
      this.config = (res.body && (await res.json())) as IRemoteConfig;

      if (this.config?.api_url) {
        localStorage.setItem(IM_REMOTE_CONFIG, JSON.stringify(this.config));
      } else {
        const localConfig: any = localStorage.getItem(IM_REMOTE_CONFIG) || "{}";
        this.config = JSON.parse(localConfig);
      }
    } catch (error) {}
    return this.config;
  };

  constructor(configUrl?: string) {
    if (KgUtils._instance) return KgUtils._instance;
    KgUtils._instance = this;
    // configUrl
    //   ? (this.configUrl = configUrl + "?t=" + new Date().getTime())
    //   : (this.config = initConfig);
    return this;
  }

  public setConfigUrl = (url) => {
    this.configUrl = url + "?t=" + new Date().getTime();
  };

  static get instance() {
    if (this._instance) return this._instance;
    return new KgUtils();
  }
  static get server() {
    return KgUtils.instance.config.api_url.replace("https://matrix.", "");
  }

  // 设置url
  public setRemoteConfigUrls(urls) {
    this.remote_config_urls = urls;
  }

  public getRemoteConfig = async (name = "") => {
    const specificConfigPromise = this.getConfig(`${name}.json`);

    try {
      const configJson = await specificConfigPromise;
      // 404s succeed with an empty json config, so check that there are keys
      if (Object.keys(configJson).length === 0) {
        throw new Error(); // throw to enter the catch
      }

      this.config = configJson;
      if (this.config?.api_url) {
        localStorage.setItem(IM_REMOTE_CONFIG, JSON.stringify(this.config));
      } else {
        const localConfig: any = localStorage.getItem(IM_REMOTE_CONFIG) || "{}";
        this.config = JSON.parse(localConfig);
      }

      return configJson;
    } catch (e) {
      return Promise.reject("");
    }
  };

  public getConfig = async (configJsonFilename: string) => {
    // 随机拿一个
    // const urlArr = this.remote_config_urls.sort(() => Math.random() - 0.5);
    const urlArr = this.remote_config_urls;

    for (let index = 0; index < urlArr.length; index++) {
      const element = urlArr[index];

      const url = new URL(configJsonFilename, element);
      url.searchParams.set("cachebuster", Date.now().toString());
      try {
        const res = await this.fetchWithTimeout(url, {
          cache: "no-cache",
          method: "GET",
          timeout: 5000,
        });

        if (res.status === 404 || res.status === 0) {
          if (index === urlArr.length - 1) {
            return {};
          }
          break;
        }

        if (res.ok) {
          return res.json();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  public fetchWithTimeout = async (resource, options: any = {}) => {
    const { timeout = 8000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);
    return response;
  };
}

export const delay = (duration: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

export const pureName = (name: string) =>
  name ? name.replace(":" + KgUtils.server, "") : "";



  export async function initRequestUrl(config, platform) {
    const handleSetApiLine = (url) => {
      platform._config["defaultHomeServer"] = url;
    };
    // 计算线路逻辑
    const localApiLine = window.localStorage.getItem("API_LINE_SET") || "auto";
    if (
      !Array.isArray(config.api_service_url) ||
      !config.api_service_url?.length
    ) {
      handleSetApiLine(config.api_url);
      return;
    } else {
      const optionsArr = await checkLineStatus(config.api_service_url);
      console.log("optionsArr---->", optionsArr);
      platform._config.apiLine = optionsArr;
      // RemoteConfig.instance.setApiLine(optionsArr);
      const okArr = optionsArr.filter(
        (i) => i.status && i.url !== "auto" && !i?.temporaryFail
      );
      // console.log("okArr---", okArr)
      if (Array.isArray(okArr) && okArr.length > 0) {
        if (localApiLine === "auto") {
          // 自动
          handleSetApiLine(okArr[0].url);
        } else {
          // 指定线路
          const find = okArr.find(
            (i) => i.url === localApiLine && !i?.temporaryFail
          );
          if (find?.status) {
            handleSetApiLine(find.url);
          } else {
            handleSetApiLine(okArr[0].url);
          }
        }
      } else {
        handleSetApiLine(config.api_url);
      }
    }
  }

export async function checkLineStatus(urls: []): Promise<T> {
  const checkDomain = (domain: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const controller = new AbortController();
      const id = window.setTimeout(() => controller.abort(), 5 * 1000);

      fetch(domain + "/_matrix/client/versions", {
        method: "GET",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache",
        // credentials: 'same-origin', // include, *same-origin, omit
        redirect: "follow",
        referrerPolicy: "no-referrer",
        signal: controller.signal,
      })
        .then((res) => {
          // console.log("res------->", res);
          if (res.status !== 200) {
            reject(false);
          } else {
            resolve(true);
          }
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          clearTimeout(id);
        });
    });
  };

  const taskArr:any = [];
  urls.forEach((i) => {
     taskArr.push(checkDomain(i));
  });

  const optionArr:any = [];
  await Promise.allSettled(taskArr)
    .then((v) => {
      // console.log('v--',v)

      v.forEach((item:any, index:number) => {
        optionArr.push({
          url: urls[index],
          status: item?.value ?? false,
          name: `线路${index + 1}`,
        });
      });
      // optionArr 排序

      optionArr.unshift({
        url: "auto",
        status: optionArr.filter(i=> i.status).length > 0,
        name: `自动`,
      });
    })
    .catch((e) => {
      console.log("e", e);
    });
  return optionArr;
}
