/*
Copyright 2020 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { ViewModel } from "../../ViewModel";
import { ApplyMap } from "../../../observable";
import { RoomTileViewModel } from "./RoomTileViewModel.js";
// import { InviteTileViewModel } from "./InviteTileViewModel.js";
// import { RoomBeingCreatedTileViewModel } from "./RoomBeingCreatedTileViewModel.js";
import { RoomFilter } from "./RoomFilter.js";
// import { addPanelIfNeeded } from "../../navigation";


export class SearchViewModel extends ViewModel {
  constructor(options) {
    super(options);
    const { client, session } = options;
    this._client = client;

    // 过滤房间
    const roomData = session.rooms.filterValues((i) => {
      const summaryData = i._summary.data;
      const tags = summaryData.tags;
      if (tags?.fake_leave) {
        return false;
      }

      if (
        summaryData.needsHeroes &&
        !summaryData.inviteCount &&
        summaryData.joinCount <= 1
      ) {
        return false;
      }

      // if (i.joinedMemberCount <= 1) return false;
      return true;
    });
    this._tileViewModelsMap = this._mapTileViewModels(
      // session.roomsBeingCreated,
      // session.invites,
      roomData
    );
    this._tileViewModelsFilterMap = new ApplyMap(this._tileViewModelsMap);
    this._tileViewModels = this._tileViewModelsFilterMap.sortValues((a, b) =>
      a.compare(b)
    );
    this._closeUrl = this.urlRouter.urlUntilSegment("session");
  }

  _mapTileViewModels( rooms) {
    // join is not commutative, invites will take precedence over rooms
    const allTiles = 
      rooms.mapValues((item, emitChange) => {
        // console.log("item---", item);
        let vm;

        vm = new RoomTileViewModel(
          this.childOptions({ room: item, emitChange })
        );
        // const isOpen = this.navigation.path.get("room")?.value === item.id;
        // if (isOpen) {
        //   vm.open();
        //   this._updateCurrentVM(vm);
        // }
        return vm;
      });
    return allTiles;
  }

  get tileViewModels() {
    return this._tileViewModels;
  }

  // _updateCurrentVM(vm) {
  //   // need to also update the current vm here as
  //   // we can't call `_open` from the ctor as the map
  //   // is only populated when the view subscribes.
  //   this._currentTileVM?.close();
  //   this._currentTileVM = vm;
  // }

  get _session() {
    return this._client.session;
  }

  async logout() {
    this.navigation.push("logout", this._client.sessionId);
  }

  async load() {}

  get closeUrl() {
    return this._closeUrl;
  }

  get deviceId() {
    return this._session.deviceId;
  }

  get userId() {
    return this._session.userId;
  }
  
  clearFilter() {
    this._tileViewModelsFilterMap.setApply(null);
    this._tileViewModelsFilterMap.applyOnce(
      (roomId, vm) => (vm.hidden = false)
    );
  }

  setFilter(query) {
    query = query.trim();
    if (query.length === 0) {
      this.clearFilter();
      return false;
    } else {
      const startFiltering = !this._tileViewModelsFilterMap.hasApply();
      const filter = new RoomFilter(query);
      this._tileViewModelsFilterMap.setApply((roomId, vm) => {
        vm.hidden = !filter.matches(vm);
      });
      return startFiltering;
    }
  }
}
