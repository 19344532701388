/*
Copyright 2022 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { ViewModel, Options as BaseOptions } from "../ViewModel";
import { SegmentType } from "../navigation/index";
import type { Session } from "../../matrix/Session.js";
import { joinRoom } from "../../matrix/room/joinRoom";
import { KgUtils } from "../../KgUtils";
import { RoomType } from "../../matrix/room/common";

type Options = BaseOptions & {
  session: Session;
};

export class AddFriendViewModel extends ViewModel<SegmentType, Options> {
  private _session: Session;
  private _joinInProgress: boolean = false;
  private _error: Error | undefined;
  private _closeUrl: any;

  constructor(options: Readonly<Options>) {
    super(options);
    this._session = options.session;
    this._closeUrl = this.urlRouter.urlUntilSegment("session");
  }

  async join(userId: string): Promise<void> {
    this._error = undefined;
    this._joinInProgress = true;
    
    this.emitChange("joinInProgress");
    if (userId[0] !== "@") userId = "@" + userId;
    if (!userId.includes(":")) userId += ":" + KgUtils.instance.config.search_domain;
    // 不能添加自己
    if (userId === this._session._user._userId) {
      this._joinInProgress = false;
      this._error = {name: '', message: this.i18n`Cannot add oneself as a friend`};
      this.emitChange("error");
      return;
    };
    try {
      const profile = await this._session._hsApi.profile(userId).response();
      if (profile?.displayname) {
        const room = this._session.findDirectMessageForUserId(userId);
        console.log("join--------", this._session, room);
        if (room) {
          this._session._hsApi
            .fake_leave_del(this.currentUserId, room.id, {})
            .response();

          this.navigation.push("room", room.id);
        } else {
          // 创建房间
          const roomBeingCreated = await this._session.createRoom({
            type: RoomType.DirectMessage,
            // preset: "trusted_private_chat",
            name: undefined,
            topic: undefined,
            isEncrypted: false,
            isFederationDisabled: true,
            alias: undefined,
            avatar: undefined,
            invites: [userId],
          });
          this.navigation.push("room", roomBeingCreated.roomId);
          window.location.reload()
          // window.document.dispatchEvent(
          //   new CustomEvent("ROOM_LIST_UPDATE", {
          //     detail: {
          //       roomId: roomBeingCreated.roomId,
          //     },
          //   })
          // );
        }
      }
    } catch (e) {
      if (e.errcode === "M_NOT_FOUND") {
        e.message = this.i18n`User does not exist`;
      }
      this._error = e;
      this.emitChange("error");
    }
    this._joinInProgress = false;
    this.emitChange("joinInProgress");
  }

  get joinInProgress(): boolean {
    return this._joinInProgress;
  }

  get closeUrl() {
    return this._closeUrl;
  }

  get currentUserId() {
    return this._session?.userId;
  }

  get status(): string | undefined {
    if (this._error) {
      return this._error.message;
    } else if (this._joinInProgress) {
      return "Joining room";
    }
  }
}
