
import { TemplateView } from "../../general/TemplateView";

export class AnnouncementView extends TemplateView {
  updateDisplayname(e) {
    const value = e.target.value;
    this._displayname = value;
    console.log("e", value);
  }

  render(t, vm) {
    return t.main({ className: "update-nickname-layer" }, [
      t.map(
        (vm) => vm.loading,
        (l, t) => {
          if (l) {
            return t.div({ className: "loading" }, "loading");
          }

          return t.div({ className: "content" }, [
            t.div({ className: "header" }, [
              t.a({
                className: "button-utility close-middle",
                href: vm.closeUrl,
              }),
            ]),
            t.div(
              {
                className: "title",
              },
              vm.i18n`Room Topic`
            ),

            // t.div(
            //   { className: "input" },
            //   t.input({
            //     type: "text",
            //     placeholder: vm.i18n`Group announcement`,
            //     value: (vm) => vm.topic,
            //     onInput: (e) => this.updateDisplayname(e),
            //     // onChange: this.updateDisplayname,
            //   })
            // ),

            t.div({ className: "form-row text" }, [
              t.textarea(
                {
                  onInput: (e) => this.updateDisplayname(e),
                  name: "topic",
                  id: "topic",
                  placeholder: vm.i18n`Room Topic`,
                  disabled: !vm.isAdmin
                },
                vm.topic
              ),
            ]),
            t.map(
              (vm) => vm.isAdmin,
              (admin, t, vm) => {
                if (admin) {
                  return t.div(
                    { className: "btn" },
                    t.button(
                      {
                        className: "button-action primary",
                        type: "submit",
                        onClick: () => vm.handleAnnouncement(this._displayname),
                      },
                      vm.i18n`Done`
                    )
                  );
                }
              }
            ),
            t.map(
              (vm) => vm.error,
              (e, t) => t.p({ className: "error" }, e)
            ),
          ]);
        }
      ),
    ]);
  }
}
