
import { ViewModel } from "../ViewModel";
import { Client } from "../../matrix/Client.js";
import { HomeServerApi } from "../../matrix/net/HomeServerApi";

// 我的页面
export class UpdateNickNameModel extends ViewModel {
  constructor(options) {
    super(options);
    const { session } = options;
    this._session = session;
    this._uid = this._session._sessionInfo.id;

    this._client = new Client(this.platform);
    this._loading = false;
    // this._closeUrl = this.urlRouter.urlUntilSegment("self");
    this._closeUrl = this.urlRouter.urlForSegment("self");
    this._error = null;
    this.load()
  }

  async load() {
    if (this._loading) {
      return;
    }
    try {
      this._loading = true;
      this.emitChange("loading");
      // this._sessionInfos = await this.platform.sessionInfoStorage.get(
      //   this._uid
      // );

      // const request = this.platform.request;
      // const homeserver = this.platform.config["defaultHomeServer"];
      // const hsApi = new HomeServerApi({ homeserver, request });
      this._profile = await this._session._hsApi.profile(this.userId).response();
    } finally {
      this._loading = false;
      this.emitChange("loading");
    }
  }

  get loading() {
    return this._loading;
  }

  get closeUrl() {
    return this._closeUrl;
  }


  get displayname() {
    return this._profile.displayname;
  }

  get userId() {
    return this._session?.userId;
  }

  get error() {
    return this._error;
  }

  setError(value) {
    this._error = value;
    this.emitChange("error");
  }

  async updateDisplayname(value) {
    if (!value) {
      this.setError(this.i18n`Please enter the remarks`);
      return;
    }
    if (value === this.displayname) {
      this.setError(this.i18n`cannot same with username`);
      return;
    }
     if (value.length >= 100) {
       this.setError(this.i18n`Length incorrect`);
       return;
     }

    // const request = this.platform.request;
    // const homeserver = this.platform.config["defaultHomeServer"];
    // const hsApi = new HomeServerApi({
    //   homeserver,
    //   request,
    //   accessToken: this._sessionInfos.accessToken,
    // });
    await this._session._hsApi.profileUpdateDisplayName(this.userId, value).response();

    // let path = this.urlRouter.urlUntilSegment("session");
    // path = path.with(this.navigation.segment(self, true));
    // this.navigation.applyPath(path);

  
    location.href = this.closeUrl;
  }
}
