import { TemplateView } from "../../general/TemplateView";
import { AvatarView } from "../../AvatarView.js";

export class MemberTileView extends TemplateView {
  render(t, vm) {
    if (vm.hide) return;

    const classes = {
      MemberTileView: true,
      manage: true,
      // [vm.membership]: true,
      hidden: (vm) => vm.hidden,
    };

    return t.li(
      {
        className: classes,
      },
      [
        t.div(
          {
            className: {
              "user-category": true,
              // "is-show": vm.showTitle,
            },
          },
          (vm) => (vm.isAdmin ? "群主" : vm.isManager ? "管理员" : "群成员")
        ),
        t.div(
          {
            className: "user-item",
            // href: `javascript:void(0)`, //vm.detailsUrl
          },
          [
            t.view(new AvatarView(vm, 52)),
            t.div({ className: "MemberTileView_name" }, [
              t.div(
                {
                  className: "name",
                  id: vm.userId,
                },
                (vm) => vm.name
              ),
              t.if(
                (vm) => vm.membership === "invite",
                (vm) =>
                  t.div(
                    {
                      className: "role",
                    },
                    "已邀请"
                  )
              ),
              t.if(
                (vm) => vm.isManager,
                (vm) =>
                  t.div(
                    {
                      className: "role",
                    },
                    "管理员"
                  )
              ),
              t.map(
                (vm) => vm.isManager,
                (admin, t, vm) => {
                  if (admin) return;

                  return t.div({ className: "action" }, [
                    t.div(
                      {
                        className: "btn invite-hide",
                        onClick: () => vm.handleUnMange("manager"),
                      },
                      "设为管理"
                    ),
                    t.div(
                      {
                        className: "btn red",
                        onClick: () => vm.handleKick(),
                      },
                      "踢出"
                    ),
                    t.map(
                      (vm) => vm.mute,
                      (mute, t, vm) => {
                        if (mute) {
                          return t.div(
                            {
                              className: "btn red invite-hide",
                              onClick: () => vm.handleMute(vm.userId),
                            },
                            "禁言"
                          );
                        }
                        return t.div(
                          {
                            className: "btn invite-hide",
                            onClick: () => vm.handleUnMute(vm.userId),
                          },
                          "取消禁言"
                        );
                      }
                    ),
                  ]);
                }
              ),
            ]),
          ]
        ),
      ]
    );
  }

  update(value, props) {
    super.update(value);
    // update the AvatarView as we told it to not subscribe itself with parentProvidesUpdates
    this.updateSubViews(value, props);
  }
}
