

import { ViewModel } from "../../ViewModel";
import { Client } from "../../../matrix/Client.js";
import { HomeServerApi } from "../../../matrix/net/HomeServerApi";

// 群名称修改
export class UpdateNameModel extends ViewModel {
  // constructor(options) {
  //   super(options);
  //   const { uid } = options;
  //   this._uid = uid;

  //   this._client = new Client(this.platform);
  //   this._loading = false;
  //   // this._closeUrl = this.urlRouter.urlUntilSegment("self");
  //   this._closeUrl = this.urlRouter.urlForSegment("self");
  //   this._error = null;
  // }

  constructor(options) {
    super(options);
    this._room = options.room;
    this._onRoomChange = this._onRoomChange.bind(this);
    this._room.on("change", this._onRoomChange);
    this._session = options.session;
    console.log("this._session", this._session);

    this._client = new Client(this.platform);
    this._loading = false;
    this._closeUrl = this.urlRouter.urlForSegment("details");
    this._error = null;
    this.sessionInfo = {};
    this.load();
  }

  get type() {
    return "room-update-name";
  }

  async load() {
    if (this._loading) {
      return;
    }
    try {
      this._loading = true;
      this.emitChange("loading");

      this._sessionInfo = await this.platform.sessionInfoStorage.get(
        this._session._sessionInfo.id
      );
    } finally {
      this._loading = false;
      this.emitChange("loading");
    }
  }

  get hideCloseButton() {
    return true;
  }

  get loading() {
    return this._loading;
  }

  get closeUrl() {
    return this._closeUrl;
  }

  get displayname() {
    return this._room.name;
  }

  get userId() {
    return this._session?.userId;
  }

  get error() {
    return this._error;
  }

  _onRoomChange() {
    this.emitChange();
  }

  dispose() {
    super.dispose();
    this._room.off("change", this._onRoomChange);
  }

  openPanel(segment) {
    let path = this.navigation.path.until("room");
    path = path.with(this.navigation.segment("right-panel", true));
    path = path.with(this.navigation.segment(segment, true));
    this.navigation.applyPath(path);
  }

  setError(value) {
    this._error = value;
    this.emitChange("error");
  }

  async updateDisplayname(value) {
    if (!value) {
      this.setError(this.i18n`Please enter a name for the room`);
      return;
    }

    if (value.length >= 100) {
      this.setError(this.i18n`Length incorrect`);
      return;
    }

    const request = this.platform.request;
    const homeserver = this.platform.config["defaultHomeServer"];
    const hsApi = new HomeServerApi({
      homeserver,
      request,
      accessToken: this._sessionInfo.accessToken,
    });
    await hsApi
      .updateMRoomName(this._room.id, {
        name: value,
      })
      .response();

    this.emitChange("displayname");
    this.openPanel("details");
  }
}
