
import {TemplateView} from "../../general/TemplateView";
import {classNames, tag} from "../../general/html";
import {AvatarView} from "../../AvatarView.js";
import { SwitchView } from "../../general/SwitchView";
import { DialogView } from "../../general/DialogView";

export class RoomDetailsView extends TemplateView {
  render(t, vm) {
    // if(vm.loading){
    //   return t.div({className: 'loading'}, vm.i18n`Loading`);
    // }
    // const encryptionString = () =>
    //   vm.isEncrypted ? vm.i18n`On` : vm.i18n`Off`;
    const classObj = {
      "content-list": true,
      "group-detail": true,
      hide: (vm) => vm.isServerNotice,
    };

    return t.div({ className: classObj }, [
      t.div(
        {
          className: {
            header: true,
            RightPanelView_buttons: true,
          },
        },
        [
          t.button({
            className: {
              back: true,
              "button-utility": true,
            },
            onClick: () => vm.back(),
          }),
        ]
      ),
      t.div({ className: "RoomDetailsView_avatar" }, [
        t.view(new AvatarView(vm, 52)),
        // t.mapView(
        //   (vm) => vm.isEncrypted,
        //   (isEncrypted) => new EncryptionIconView(isEncrypted)
        // ),

        t.map(
          (vm) => vm.customName,
          (name, t) => t.div({ className: "nickname remark" }, name)
        ),

        t.div(
          {
            className: "send-message",
            onClick: () => vm.openDirectMessage(),
          },
          vm.i18n`Send Messages`
        ),
      ]),
      this._hr(t),

      t.map(
        (vm) => vm.name,
        (name, t, vm) => {
          return this._row({
            t,
            label: vm.i18n`Group name`,
            content: name,
            clickFun: () => vm.isAdmin && vm.openPanel("updatename"),
            arrow: vm.isAdmin ? true : false,
            extraClass: "group-name",
          });
        }
      ),
      this._hr(t),

      t.map(
        (vm) => vm.topic,
        (topic, t, vm) => {
          return this._row({
            t,
            label: vm.i18n`Group announcement`,
            content: topic,
            clickFun: () => vm.openPanel("announcement"),
            extraClass: "topic",
          });
        }
      ),
      this._hr(t),
      // this._row({
      //   t,
      //   label: vm.i18n`Group Code`,
      //   content: t.div({ className: "qrcode" }),
      //   clickFun: () => vm.openPanel("qrcode"),
      // }),
      // this._hr(t),
      t.if(
        (vm) => vm.isAdmin,
        (t, vm) =>
          t.div({}, [
            this._row({
              t,
              label: vm.i18n`Group member management`,
              content: vm.memberCount + "",
              clickFun: () => vm.openPanel("member-management"),
            }),
            this._hr(t),
          ])
      ),

      t.if(
        (vm) => vm.isAdmin,
        (t, vm) =>
          t.div({}, [
            this._row({
              t,
              label: vm.i18n`Clear Chat Record`,
              content: "",
              clickFun: () => vm.clearHistory(),
            }),
            this._hr(t),
          ])
      ),

      this._row({
        t,
        label: vm.i18n`Message exemption`,
        content: t.map(
          (vm) => vm.disturbStatus,
          (disturb, t) => {
            return t.view(
              new SwitchView({
                checked: disturb,
                onclick: () => vm.doNotDisturb(),
              })
            );
          }
        ),
        arrow: false,
        extraClass: "exemption",
        // clickFun: () => vm.doNotDisturb(),
      }),
      this._hr(t),

      // this._row({
      //   t,
      //   label: vm.i18n`Show membership nickname`,
      //   content: t.view(
      //     new SwitchView({
      //       checked: false,
      //     })
      //   ),
      //   arrow: false,
      // }),
      // this._hr(t),
      // this._row({
      //   t,
      //   label: vm.i18n`Administrator can modify the group name`,
      //   content: t.view(
      //     new SwitchView({
      //       checked: false,
      //     })
      //   ),
      //   arrow: false,
      // }),
      // this._hr(t),
      // this._row({
      //   t,
      //   label: vm.i18n`Administrator can modify the group avatar`,
      //   content: t.view(
      //     new SwitchView({
      //       checked: false,
      //     })
      //   ),
      //   arrow: false,
      // }),
      // this._hr(t),
      // this._row({
      //   t,
      //   label: vm.i18n`Administrator can modify the group announcement`,
      //   content: t.view(
      //     new SwitchView({
      //       checked: false,
      //     })
      //   ),
      //   arrow: false,
      // }),
      // this._hr(t),
      t.if(
        (vm) => vm.isAdmin,
        (t, vm) =>
          t.div({}, [
            this._row({
              t,
              label: vm.i18n`All prohibited`,
              content: t.map(
                (vm) => vm.eventsDefault,
                (e, t) => {
                  return t.view(
                    new SwitchView({
                      checked: e ? true : false,
                      disabled: true,
                      onclick: () => vm.confirmMuteDialog(),
                    })
                  );
                }
              ),
              arrow: false,
              // clickFun: () => vm.confirmMuteDialog(),
            }),
            this._hr(t),
          ])
      ),

      // this._row({
      //   t,
      //   label: vm.i18n`Encryption`,
      //   content: t.view(
      //     new SwitchView({
      //       checked: vm.isEncrypted,
      //     })
      //   ),
      //   arrow: false,
      // }),
      // this._hr(t),

      // t.if(
      //   (vm) => vm.isAdmin,
      //   (t, vm) =>
      //     t.div({}, [
      //       this._row({
      //         t,
      //         label: vm.i18n`People`,
      //         content: vm.memberCount + "",
      //         clickFun: () => vm.openPanel("members"),
      //       }),
      //       this._hr(t),
      //     ])
      // ),

      t.map(
        (vm) => vm.isAdmin,
        (isAdm, t, vm) => {
          if (isAdm) {
            return t.div(
              {
                className: "button error leave",
                onClick: () => vm.DeleteGroup(),
              },
              vm.i18n`Leave the group`
            );
          } else {
            return t.div(
              {
                className: "button error leave",
                onClick: () => vm.LeaveGroup(),
              },
              vm.i18n`Leave the group`
            );
          }
        }
      ),

      //  提示
      t.map(
        (vm) => vm.notify,
        (n, t) => {
          if (n) {
            return t.div(
              {
                className: "notify show",
              },
              n
            );
          }
        }
      ),

      // 清理消息
      t.map(
        (vm) => vm.showClearHistory,
        (show, t, vm) => {
          if (show) {
            return new DialogView({
              content: vm.i18n`Confirm clear history`,
              cancel: {
                text: vm.i18n`Cancel`,
                onClick: () => vm.clearHistory("hide"),
              },
              confirm: {
                text: vm.i18n`Confirm`,
                onClick: () => vm.handleClearHistory(),
              },
            }).render(t);
          } else {
            return t.div({ className: "hide" }, "");
          }
        }
      ),

      // 弹窗-- 离开房间
      t.map(
        (vm) => vm.showLeaveDialog,
        (show, t, vm) => {
          if (show) {
            return new DialogView({
              content:
                vm.i18n`Are you sure you want to leave the room '%(roomName)s'?`.replace(
                  "%(roomName)s",
                  vm.name
                ),
              cancel: {
                text: vm.i18n`Cancel`,
                onClick: () => vm.LeaveCancel(),
              },
              confirm: {
                text: vm.i18n`Confirm`,
                onClick: () => vm.LeaveConfirm(),
              },
            }).render(t);
          } else {
            return t.div({ className: "hide" }, "");
          }
        }
      ),

      // 全体禁言弹窗
      t.map(
        (vm) => vm.showMuteDialog,
        (show, t, vm) => {
          if (show) {
            return new DialogView({
              content: vm.eventsDefault
                ? vm.i18n`Unmute all`
                : vm.i18n`Confirm Mute all`,
              cancel: {
                text: vm.i18n`Cancel`,
                onClick: () => vm.confirmMuteDialogCancel(),
              },
              confirm: {
                text: vm.eventsDefault ? vm.i18n`Unmute` : vm.i18n`Mute all`,
                onClick: () => vm.muteDialogCancelConfirm(),
              },
            }).render(t);
          } else {
            return t.div({ className: "hide" }, "");
          }
        }
      ),
    ]);
  }

  _row(props) {
    const {
      t,
      label,
      content,
      extraClass = "",
      arrow = true,
      clickFun = () => {},
    } = props;
    return t.div({ className: `row ${extraClass}`, onClick: clickFun }, [
      t.div({ className: "label" }, label),
      t.div({ className: "content ellipsis" }, content),
      t.div({ className: `${arrow ? "arrow" : "hide"}` }),
    ]);
  }

  _hr(t) {
    return t.div({ className: "hr" });
  }

  _createRoomAliasDisplay(vm) {
    return vm.canonicalAlias
      ? tag.div({ className: "RoomDetailsView_id" }, [vm.canonicalAlias])
      : "";
  }

  _createRightPanelRow(t, label, labelClass, value) {
    const labelClassString = classNames({
      RoomDetailsView_label: true,
      ...labelClass,
    });
    return t.div({ className: "RoomDetailsView_row" }, [
      t.div({ className: labelClassString }, [label]),
      t.div({ className: "RoomDetailsView_value" }, value),
    ]);
  }

  _createRightPanelButtonRow(t, label, labelClass, value, onClick) {
    const labelClassString = classNames({
      RoomDetailsView_label: true,
      ...labelClass,
    });
    return t.button({ className: "RoomDetailsView_row", onClick }, [
      t.div({ className: labelClassString }, [label]),
      t.div({ className: "RoomDetailsView_value" }, value),
    ]);
  }

 
}

class EncryptionIconView extends TemplateView {
    render(t, isEncrypted) {
        return t.div({className: "EncryptionIconView"},
            [t.div({className: isEncrypted ? "EncryptionIconView_encrypted" : "EncryptionIconView_unencrypted"})]);
    }
}
