import { TemplateView } from "../../general/TemplateView";
import { AvatarView } from "../../AvatarView.js";

export class GroupQRCodeView extends TemplateView {
  render(t, vm) {
    return t.main({ className: "qrcode-layer" }, [
      t.map(
        (vm) => vm.loading,
        (l, t) => {
          if (l) {
            return t.div({ className: "loading" }, "loading");
          }

          // view
          return t.div({ className: "content" }, [
            t.div({ className: "header" }, [
              t.a({
                className: "button-utility close-middle",
                href: vm.closeUrl,
              }),
            ]),

            t.div(
              {
                className: "group-avatar",
              },
              t.view(new AvatarView(vm, 64))
            ),
            t.div(
              {
                className: "nickname",
              },
              vm.displayname
            ),
            t.div(
              {
                className: "code",
              },
              [t.img({ className: "img", src: vm.QRCode })]
            ),
          ]);
        }
      ),
    ]);
  }
}
