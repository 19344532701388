

import { TemplateView } from "../../general/TemplateView";

// test123 创建了此群。 这里是 222 的开始。
// 话题：21121

export class GroupHeader extends TemplateView {
  render(t,vm) {

    return t.div(
      {
        className: "group-header",
      },
      [
        t.map(
          (vm) => vm.name,
          (n, t) => t.div({ className: "info" }, `这里是 ${n} 的开始。`)
        ),
        t.map(
          (vm) => vm.isAdmin,
          (a, t, vm) => {
            if (a) {
              return t.div(
                {
                  className: "btn",
                  onclick: () => vm.inviteUser(),
                },
                "邀请到此群"
              );
            }
          }
        ),
      ]
    );
  }
}

