

import { TemplateView } from "./TemplateView";


export class DialogView extends TemplateView {
 
  constructor(options) {
    super();
    this._options = options;
  }

  render(t) {
    return t.div({ className: "dialog-layer" }, [
      t.div({ 
        className: "mark",
        onClick: this._options.cancel.onClick, 
      }),
      t.div(
        {
          className: "main",
        },
        [
          t.div({ className: "dialog_content" }, this._options.content),
          t.div(
            {
              className: "bottom",
            },
            [
              t.div(
                {
                  className: "cancel",
                  onClick: this._options.cancel.onClick,
                },
                this._options.cancel.text
              ),
              t.div(
                {
                  className: "confirm",
                  onClick: this._options.confirm.onClick,
                },
                this._options.confirm.text
              ),
            ]
          ),
        ]
      ),
    ]);
  }
}

