import { AvatarView } from "../../AvatarView.js";
// import { SwitchView } from "../../general/SwitchView.js";
import { TemplateView } from "../../general/TemplateView";
import { DialogView } from "../../general/DialogView";
import { LoadingView } from "../../general/LoadingView";

export class GroupMemberOperate extends TemplateView {
  render(t, vm) {
    return t.div({ className: " operate-member-layer" }, [
      t.map(
        (vm) => vm.loading,
        (l, t) => {
          if (l) {
            return t.view(new LoadingView(vm.i18n`Loading`));
          }

          // 管理员
          if (vm.currentUserIsAdmin) {
            return t.div({ className: "content-list member-detail" }, [
              t.div(
                {
                  className: {
                    header: true,
                    RightPanelView_buttons: true,
                    operate_member: true,
                  },
                },
                [
                  t.button({
                    className: {
                      back: true,
                      "button-utility": true,
                    },
                    onClick: () => vm.back(),
                  }),
                ]
              ),
              t.div({ className: "user-avatar" }, [
                t.view(new AvatarView(vm, 64)),
                t.div(
                  {
                    className: "info",
                  },
                  [
                    t.map(
                      (vm) => vm.name,
                      (name, t) => t.div({ className: "nickname remark" }, name)
                    ),
                    t.div({ className: "nickname" }, vm.showUserId),
                    t.div({ className: "nickname remark" }, vm.i18n`Admin`),
                  ]
                ),
              ]),
            ]);
          }

       

          // 普通用户
          return t.div({ className: "content-list member-detail" }, [
            t.div(
              {
                className: {
                  header: true,
                  RightPanelView_buttons: true,
                  operate_member: true,
                  leave: vm.isLeave,
                },
              },
              [
                t.button({
                  className: {
                    back: true,
                    "button-utility": true,
                  },
                  onClick: () => vm.back(),
                }),
              ]
            ),
            t.div({ className: "user-avatar" }, [
              t.view(new AvatarView(vm, 64)),
              t.div(
                {
                  className: "info",
                },
                [
                  t.map(
                    (vm) => vm.name,
                    (name, t) => t.div({ className: "nickname remark" }, name)
                  ),
                  t.div({ className: "nickname" }, vm.showUserId),
                ]
              ),
            ]),

            this._hr(t),
            this._row({
              t,
              label: vm.i18n`Clean up all the information of this user`,
              clickFun: () => vm.clearHistory(""),
            }),
            this._hr(t),

            this._row({
              t,
              label: vm.i18n`Make it an administrator`,
              extraClass: vm.isLeave ? "hide" : "",
              clickFun: () => vm.showAdminDialog(),
            }),
            this._hr(t, vm.isLeave),

            t.map(
              (vm) => vm.mute,
              (mute, t) =>
                vm.isLeave
                  ? ""
                  : mute
                  ? this._row({
                      t,
                      label: vm.i18n`Unmute`,
                      clickFun: () => vm.handleUnMute(),
                    })
                  : this._row({
                      t,
                      label: vm.i18n`Make it a banned user`,
                      extraClass: "red",
                      clickFun: () => vm.handleMute(),
                    })
            ),
            this._hr(t, vm.isLeave),

            // this._row({
            //   t,
            //   label: vm.i18n`Set the title`,
            // }),
            // this._hr(t),

            this._row({
              t,
              label: vm.i18n`Remove from group`,
              extraClass: vm.isLeave ? "hide" : "red",
              clickFun: () => vm.removeDialog(),
            }),
            this._hr(t, vm.isLeave),

            t.map(
              (vm) => vm.isLeave,
              (leave, t) =>
              !leave ? '' :
                leave === "leave"
                  ? this._row({
                      t,
                      label: vm.i18n`Invites`,
                      clickFun: () => vm.handleInvites(),
                    })
                  : this._row({
                      t,
                      label: vm.i18n`Invited`,
                      arrow: false,
                    })
            ),
            this._hr(t, !vm.isLeave),
            // this._row({
            //   t,
            //   label: vm.i18n`The group was banned from now on`,
            //   extraClass: "red",
            // }),

            // this._hr(t),

            //  提示
            t.map(
              (vm) => vm.notify,
              (n, t) => {
                if (n) {
                  return t.div(
                    {
                      className: "notify show",
                    },
                    n
                  );
                }
              }
            ),

            // 清理消息
            t.map(
              (vm) => vm.showClearHistory,
              (show, t, vm) => {
                if (show) {
                  return new DialogView({
                    content: vm.i18n`Confirm clear history`,
                    cancel: {
                      text: vm.i18n`Cancel`,
                      onClick: () => vm.clearHistory("hide"),
                    },
                    confirm: {
                      text: vm.i18n`Confirm`,
                      onClick: () => vm.handleClearHistory(),
                    },
                  }).render(t);
                } else {
                  return t.div({ className: "hide" }, "");
                }
              }
            ),

            // 删除
            t.map(
              (vm) => vm.showRemoveConfirmDialog,
              (show, t, vm) => {
                if (show) {
                  return new DialogView({
                    content: t.div({ className: "kick-layer" }, [
                      t.div(
                        { className: "desc" },
                        vm.i18n`Kick the user will remove the user from this group`
                      ),
                      t.label({ className: "checkbox-layer" }, [
                        t.input({
                          className: "checkbox",
                          type: "checkbox",
                          onChange: (v) => vm.handleKickCheckbox(v),
                        }),
                        t.span(
                          { className: "text" },
                          vm.i18n`Delete all message records in the group`
                        ),
                      ]),
                    ]),
                    cancel: {
                      text: vm.i18n`Cancel`,
                      onClick: () => vm.hideDialog("_showRemoveConfirmDialog"),
                    },
                    confirm: {
                      text: vm.i18n`Confirm`,
                      onClick: () => vm.handleKick(),
                    },
                  }).render(t);
                } else {
                  return t.div({ className: "hide" }, "");
                }
              }
            ),

            // 封禁
            t.map(
              (vm) => vm.showDisabledConfirmDialog,
              (show, t, vm) => {
                if (show) {
                  return new DialogView({
                    content: vm.i18n`Confirm clear history`,
                    cancel: {
                      text: vm.i18n`Cancel`,
                      onClick: () =>
                        vm.hideDialog("_showDisabledConfirmDialog"),
                    },
                    confirm: {
                      text: vm.i18n`Confirm`,
                      onClick: () => vm.handleClearHistory(),
                    },
                  }).render(t);
                } else {
                  return t.div({ className: "hide" }, "");
                }
              }
            ),

            // 设置管理员
            t.map(
              (vm) => vm.showAdminConfirmDialog,
              (show, t, vm) => {
                if (show) {
                  return new DialogView({
                    content: vm.name + " " + vm.i18n`Make it an administrator`,
                    cancel: {
                      text: vm.i18n`Cancel`,
                      onClick: () => vm.hideDialog("_showAdminConfirmDialog"),
                    },
                    confirm: {
                      text: vm.i18n`Confirm`,
                      onClick: () => vm.handleRole(),
                    },
                  }).render(t);
                } else {
                  return t.div({ className: "hide" }, "");
                }
              }
            ),
          ]);
        }
      ),
    ]);
  }

  _row(props) {
    const {
      t,
      label,
      content,
      extraClass = "",
      arrow = true,
      clickFun = () => {},
    } = props;
    return t.div({ className: `row ${extraClass}`, onClick: clickFun }, [
      t.div({ className: "label" }, label),
      t.div({ className: "content" }, content),
      t.div({ className: `${arrow ? "arrow" : "hide"}` }),
    ]);
  }

  _hr(t, hide) {
    return t.div({ className: `hr ${hide ? 'hide': ''}` });
  }

  _createSection(t, label, value) {
    return t.div({ className: "MemberDetailsView_section" }, [
      t.div({ className: "MemberDetailsView_label" }, label),
      t.div({ className: "MemberDetailsView_value" }, value),
    ]);
  }

  _createOptions(t, vm) {
    return t.div({ className: "MemberDetailsView_section" }, [
      t.div({ className: "MemberDetailsView_label" }, vm.i18n`Options`),
      t.div({ className: "MemberDetailsView_options" }, [
        t.a(
          { href: vm.linkToUser, target: "_blank", rel: "noopener" },
          vm.i18n`Open Link to User`
        ),
        t.button(
          { className: "text", onClick: () => vm.openDirectMessage() },
          vm.i18n`Open direct message`
        ),
      ]),
    ]);
  }
}
