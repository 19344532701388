import { get, set } from "lodash";
export class IMData {
  constructor() {
    this.friendNickName = {}; // 好友昵称
    this.roomPinnedData = {}; // 房间置顶信息
    this.loginToken = '';
  }

  // 设置好友昵称
  setFriendNickName(rooms) {
    // console.log("rooms---", rooms, rooms._source._values.entries());
    const roomsList = rooms._source._values.entries();
    for (let val of roomsList) {
      const isFriendRoom = get(val, [1, "_summary", "data", "isDirectMessage"]);
      const customName = get(
        val,
        [1, "_summary", "data", "tags", "custom_name", "text"],
        ""
      );
      if (customName) {
        const joinCount = get(val, [1, "_summary", "data", "joinCount"]);

        const dmUserId =
          get(val, [1, "_summary", "data", "dmUserId"], "") ||
          get(val, [1, "_summary", "data", "heroes", 0], "");
        if (customName && joinCount === 2 && dmUserId) {
          this.friendNickName[dmUserId] = customName;
        }
      }
    }
  }

  // 更新好友昵称
  updateFriendNickName(uid, displayName) {
    this.friendNickName[uid] = displayName;
  }

  // 获取好友昵称
  getFriendNickName(uid) {
    return get(this.friendNickName, [uid], "");
  }

  //房间置顶信息
  setRoomPinnedData(roomId, ids) {
    this.roomPinnedData[roomId] = ids
  }

  getRoomPinnedData(roomId){
    return get(this.roomPinnedData, [roomId], [])
  }

  setLoginToken(val){
    this.loginToken = val;
  }

}
