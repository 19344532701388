
import { ViewModel } from "../../ViewModel";
import { Client } from "../../../matrix/Client.js";
import { avatarInitials, getIdentifierColorNumber } from "../../avatar";
import QRCode from "qrcode";
import {
  getAvatarHttpUrl,
} from "../../avatar";
import { HomeServerApi } from "../../../matrix/net/HomeServerApi";

// 群二维码
export class QRCodeModel extends ViewModel {
  constructor(options) {
    super(options);
    this._room = options.room;
    this._onRoomChange = this._onRoomChange.bind(this);
    this._room.on("change", this._onRoomChange);
    this._session = options.session;
    console.log("this._session", this._session);

    this._client = new Client(this.platform);
    this._loading = false;
    this._closeUrl = this.urlRouter.urlForSegment("details");
    this._error = null;
    this.sessionInfo = {};
    this.load();
  }

  async load() {
    if (this._loading) {
      return;
    }
    try {
      this._loading = true;
      this.emitChange("loading");

      // https://to.gptchat.fan/#/!CclxiEemEHxOsTMqeR:gptchat.fan?via=gptchat.fan
      let QRCodeText = `${this.platform._config.permalink_prefix}/#/${this.roomId}?via=gptchat.fan`;
      this._QRCode = await this.generateQR(QRCodeText);
    } finally {
      this._loading = false;
      this.emitChange("loading");
    }
  }

  async generateQR(text) {
    try {
      return await QRCode.toDataURL(text);
    } catch (err) {
      console.error(err);
    }
  }

  get type() {
    return "room-qrcode";
  }

  get roomId() {
    return this._room.id;
  }

  get closeUrl() {
    return this._closeUrl;
  }

  get displayname() {
    return this._room.name;
  }

  get loading() {
    return this._loading;
  }

  get QRCode() {
    return this._QRCode;
  }

  get userId() {
    return this._session?.userId;
  }

  get avatarColorNumber() {
    return getIdentifierColorNumber(this.userId);
  }

  get avatarInitials() {
    return avatarInitials(this.userId);
  }

  avatarUrl(size) {
    return getAvatarHttpUrl(
      this._room.avatarUrl,
      size,
      this.platform,
      this._room.mediaRepository
    );
  }

  _onRoomChange() {
    this.emitChange();
  }

  dispose() {
    super.dispose();
    this._room.off("change", this._onRoomChange);
  }

  openPanel(segment) {
    let path = this.navigation.path.until("room");
    path = path.with(this.navigation.segment("right-panel", true));
    path = path.with(this.navigation.segment(segment, true));
    this.navigation.applyPath(path);
  }
}
