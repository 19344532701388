
import {AvatarView} from "../../AvatarView.js";
import { SwitchView } from "../../general/SwitchView.js";
import {TemplateView} from "../../general/TemplateView";
import { DialogView } from "../../general/DialogView";

export class MemberDetailsView extends TemplateView {
    render(t, vm) {

      
        return t.div({ className: "content-list member-detail" }, [
          t.div(
            {
              className: {
                header: true,
                RightPanelView_buttons: true,
              },
            },
            [
              t.button({
                className: {
                  back: true,
                  "button-utility": true,
                },
                onClick: () => vm.back(),
              }),
            ]
          ),
          t.div({ className: "user-avatar" }, [
            t.view(new AvatarView(vm, 64)),
            t.div(
              {
                className: "info",
              },
              [
                t.map(
                  (vm) => vm.customName,
                  (name, t) => t.div({ className: "nickname remark" }, name)
                ),
                t.div({ className: "nickname" }, vm.name),
                t.div({ className: "nickname" }, vm.showUserId),

                t.div(
                  {
                    className: "send-message",
                    onClick: () => vm.openDirectMessage(),
                  },
                  vm.i18n`Send Messages`
                ),
              ]
            ),
          ]),
          this._hr(t),
          t.map(
            (vm) => vm.customName,
            (name, t, vm) => {
              return this._row({
                t,
                label: vm.i18n`Comment`,
                content: name,
                clickFun: () => vm.openPanel("member-update-remarks"),
              });
            }
          ),

          this._hr(t),
          this._row({
            t,
            label: vm.i18n`Message exemption`,
            content: t.map(
              (vm) => vm.disturbStatus,
              (disturbStatus, t, vm) => {
                return t.view(
                  new SwitchView({
                    checked: disturbStatus ? true : false,
                    onchange: () => vm.doNotDisturb(),
                  })
                );
              }
            ),
            arrow: false,
          }),
          this._hr(t),
          this._row({
            t,
            label: vm.i18n`Clear Chat Record`,
            // content: vm.showMessage + "",
            clickFun: () => vm.clearHistory(),
          }),
          this._hr(t),
          // this._row({
          //   t,
          //   label: vm.i18n`add to blacklist`,
          //   content: t.view(
          //     new SwitchView({
          //       checked: false,
          //     })
          //   ),
          //   arrow: false,
          // }),
          // this._hr(t),

          // this._row({
          //   t,
          //   label: vm.i18n`Role`,
          //   content: vm.role,
          //   arrow: false,
          // }),
          // this._hr(t),
          // this._row({
          //   t,
          //   label: vm.i18n`Security`,
          //   content: vm.isEncrypted
          //     ? vm.i18n`Messages in this room are end-to-end encrypted.`
          //     : vm.i18n`Messages in this room are not end-to-end encrypted.`,
          //   arrow: false,
          // }),
          // this._row({
          //   t,
          //   label: vm.i18n`Security`,
          //   content: t.view(
          //     new SwitchView({
          //       checked: vm.isEncrypted,
          //       // onchange: () => vm.doNotDisturb(),
          //     })
          //   ),
          //   arrow: false,
          // }),
          // this._hr(t),

          

          t.div(
            {
              className: "button error",
              onClick: () => vm.LeaveRoom(),
            },
            vm.i18n`Delete friend`
          ),

          //  提示
          t.map(
            (vm) => vm.notify,
            (n, t) => {
              if (n) {
                return t.div(
                  {
                    className: "notify show",
                  },
                  n
                );
              }
            }
          ),

          // 清理消息
          t.map(
            (vm) => vm.showClearHistory,
            (show, t, vm) => {
              if (show) {
                return new DialogView({
                  content: vm.i18n`Confirm clear history`,
                  cancel: {
                    text: vm.i18n`Cancel`,
                    onClick: () => vm.clearHistory("hide"),
                  },
                  confirm: {
                    text: vm.i18n`Confirm`,
                    onClick: () => vm.handleClearHistory(),
                  },
                }).render(t);
              } else {
                return t.div({ className: "hide" }, "");
              }
            }
          ),

          // 弹窗
          t.map(
            (vm) => vm.showLeaveDialog,
            (show, t, vm) => {
              if (show) {
                return new DialogView({
                  content: vm.i18n`Are you sure you want to delete your friends`,
                  cancel: {
                    text: vm.i18n`Cancel`,
                    onClick: () => vm.LeaveCancel(),
                  },
                  confirm: {
                    text: vm.i18n`Confirm`,
                    onClick: () => vm.LeaveConfirm(),
                  },
                }).render(t);
              } else {
                return t.div({ className: "hide" }, "");
              }
            }
          ),
        ]);
    }

    _row(
        props
      ) {
        const {
          t,
        label,
        content,
        extraClass = "",
        arrow = true,
        clickFun = () => {}} = props
        return t.div({ className: `row ${extraClass}`, onClick: clickFun }, [
          t.div({ className: "label" }, label),
          t.div({ className: "content" }, content),
          t.div({ className: `${arrow ? "arrow" : "hide"}` }),
        ]);
      }

      _hr(t){
        return t.div({ className: "hr" })
      }

    _createSection(t, label, value) {
        return t.div({ className: "MemberDetailsView_section" },
            [
                t.div({className: "MemberDetailsView_label"}, label),
                t.div({className: "MemberDetailsView_value"}, value)
            ]);
    }

    _createOptions(t, vm) {
        return t.div({ className: "MemberDetailsView_section" },
            [
                t.div({className: "MemberDetailsView_label"}, vm.i18n`Options`),
                t.div({className: "MemberDetailsView_options"},
                    [
                        t.a({href: vm.linkToUser, target: "_blank", rel: "noopener"}, vm.i18n`Open Link to User`),
                        t.button({className: "text", onClick: () => vm.openDirectMessage()}, vm.i18n`Open direct message`)
                    ])
            ]);
    }
}
