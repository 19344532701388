
import { ViewModel } from "../../ViewModel";
import { Client } from "../../../matrix/Client.js";
import { HomeServerApi } from "../../../matrix/net/HomeServerApi";
import { ApplyMap } from "../../../observable";
import { MembersManageSelectFilter } from "./MembersManageSelectFilter.js";
import {MembersManageSelectTileViewModel} from "./MembersManageSelectTileViewModel"
import _ from "lodash"

// 选择用户
export class MembersManageSelectModel extends ViewModel {
  constructor(options) {
    super(options);
    const { session } = options;
    this._room = options.room;
    this._members = options.members;
    // this._onRoomChange = this._onRoomChange.bind(this);
    // this._room.on("change", this._onRoomChange);
    this._session = options.session;

    this._roomMemberIds = [];
    const values = this._members.members.values();
    for (let item of values) {
      if (item.membership !== "leave") {
        this._roomMemberIds.push(item.userId);
      }
    }

    this._tileViewModelsMap = this._mapTileViewModels(
      // session.roomsBeingCreated,
      // session.invites,
      session.rooms.filterValues(
        (m) =>{
          const fUserId = _.get(m, ["_summary", "data", "dmUserId"]) ||  _.get(m, ["_summary", "data", "heroes", 0]);
          return m.joinedMemberCount > 1 &&
            !m._summary.data.tags?.fake_leave &&
            !this._roomMemberIds.includes(fUserId) &&
            m.id !== m.avatarColorId;
        }
      )
    );
    this._tileViewModelsFilterMap = new ApplyMap(this._tileViewModelsMap);
    this._tileViewModels = this._tileViewModelsFilterMap.sortValues((a, b) =>
      a.compare(b)
    );
    this._selectedNum = 0;
    this._selectedInviteUserIds = [];
    this._isInviteIng = false;

    // this._client = new Client(this.platform);
    this._loading = false;
    this.load();
  }

  _mapTileViewModels(rooms) {
    // join is not commutative, invites will take precedence over rooms
    const allTiles = rooms.mapValues((item, emitChange) => {
      let vm;

      if (item.isBeingCreated) {
      } else if (item.isInvite) {
      } else {
        // console.log("item--->", item, item.id);

        vm = new MembersManageSelectTileViewModel(
          this.childOptions({
            room: item,
            emitChange,
            roomMemberIds: this._roomMemberIds,
          })
        );
      }
      const isOpen = this.navigation.path.get("room")?.value === item.id;
      if (isOpen && vm) {
        vm.open();
        this._updateCurrentVM(vm);
      }

      return vm;
    });
    return allTiles;
  }

  _updateCurrentVM(vm) {
    // need to also update the current vm here as
    // we can't call `_open` from the ctor as the map
    // is only populated when the view subscribes.
    this._currentTileVM?.close();
    this._currentTileVM = vm;
  }

  _open(roomId) {
    this._currentTileVM?.close();
    this._currentTileVM = null;
    if (roomId) {
      this._currentTileVM = this._tileViewModelsMap.get(roomId);
      this._currentTileVM?.open();
    }
  }

  async load() {
    if (this._loading) {
      return;
    }
    try {
      this._loading = true;
      this.emitChange("loading");
      this._sessionInfo = await this.platform.sessionInfoStorage.get(
        this._session._sessionInfo.id
      );

      // 监听选择数量
      window.document.addEventListener(
        "invite_user_count",
        this.handleInviteCount.bind(this)
      );

    } finally {
      this._loading = false;
      this.emitChange("loading");
    }
  }

  get type() {
    return "member-list-select";
  }

  get shouldShowBackButton() {
    return false;
  }

  get hideCloseButton() {
    return true;
  }

  get loading() {
    return this._loading;
  }

  get userId() {
    return this._session?.userId;
  }

  get roomId() {
    return this._room.id;
  }

  get tileViewModels() {
    return this._tileViewModels;
  }

  get selectedNum() {
    return this._selectedNum;
  }

  get isInviteIng() {
    return this._isInviteIng;
  }

  handleInviteCount(e) {
    const inviteId = e.detail;
    if (inviteId && !this._selectedInviteUserIds.includes(inviteId)) {
      this._selectedInviteUserIds.push(inviteId);
    } else if (this._selectedInviteUserIds.includes(inviteId)) {
      _.remove(this._selectedInviteUserIds, (i) => i === inviteId);
    }
    this._selectedNum = this._selectedInviteUserIds.length;
    this.emitChange("selectedNum");
  }

  clearFilter() {
    this._tileViewModelsFilterMap.setApply(null);
    this._tileViewModelsFilterMap.applyOnce(
      (roomId, vm) => (vm.hidden = false)
    );
  }

  setFilter(query) {
    query = query.trim();
    if (query.length === 0) {
      this.clearFilter();
      return false;
    } else {
      const startFiltering = !this._tileViewModelsFilterMap.hasApply();
      const filter = new MembersManageSelectFilter(query);
      this._tileViewModelsFilterMap.setApply((roomId, vm) => {
        vm.hidden = !filter.matches(vm);
      });
      return startFiltering;
    }
  }

  handleUserSelected() {
    console.log("handleUserSelected");
  }

  // _onRoomChange() {
  //   this.emitChange();
  // }

  dispose() {
    super.dispose();
    // this._room.off("change", this._onRoomChange);
  }

  openPanel(segment) {
    let path = this.navigation.path.until("room");
    path = path.with(this.navigation.segment("right-panel", true));
    path = path.with(this.navigation.segment(segment, true));
    this.navigation.applyPath(path);
  }

  // 发送邀请
  async handleInvite() {
    if(!this.selectedNum){
      alert("请选择要邀请的用户")
      return;
    }

    if (this._isInviteIng) {
      return;
    }
    this._isInviteIng = true;
    this.emitChange("isInviteIng");

    console.log(
      "inviteList --进入",
      this._room.id,
      this._tileViewModelsFilterMap
    );

    let inviteList = [];
    // this._tileViewModelsFilterMap.applyOnce((key, vm) => {
    this._tileViewModelsFilterMap.setApply((key, vm) => {
      // console.log("key, value", value.selected, key, value);
      if (vm.selected) {
        // vm.hidden = true;
        // this._roomMemberIds.push(vm.userId);
        this._roomMemberIds.push(vm.options.room.avatarColorId);
        inviteList.push(vm.options.room.avatarColorId);
      }
    });

    if (inviteList.length === 0) return;

    // 邀请选中用户
    const request = this.platform.request;
    const homeserver = this.platform.config["defaultHomeServer"];
    // const sessionId = this.navigation.path.get("session")?.value;
    // this._sessionInfo = await this.platform.sessionInfoStorage.get(sessionId);

    const hsApi = new HomeServerApi({
      homeserver,
      request,
      accessToken: this._sessionInfo.accessToken,
    });

    // todo new Promise();

    inviteList.forEach(async (i) => {
      await hsApi
        .roomInvite(this.roomId, {
          user_id: i,
          reason: "must",
        })
        .response();
    });

    this.openPanel("member-management");
  }
}
