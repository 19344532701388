
// 多语言
export class I18N {
  static t;
  static setLang;
  static lang: string;
  static langStore: any;

  constructor() {
    I18N.setLang = this.setLang;
    I18N.t = this.getTranslated;
  }

  public async setLang(lang: string, langData:any) {
    if (lang === "en") lang = "en_EN";
    if (lang === "zh") lang = "zh_Hans";
    I18N.lang = lang;
    I18N.langStore = langData;
    // await this.fetchLangJson();
  }

  public async fetchLangJson() {
    return new Promise((resolve) => {
      // fetch("/i18n/" + I18N.lang + ".json")
      //   .then((res) => res.json())
      //   .then((data) => {
      //     I18N.langStore = data;
      //     resolve(true);
      //   });
    });
  }

  public getTranslated(parts) {
    return I18N.langStore[parts] || parts;
  }
}
