import { ViewModel } from "../ViewModel";
import { Client } from "../../matrix/Client.js";
import {
  avatarInitials,
  getIdentifierColorNumber,
  getAvatarHttpUrl,
} from "../avatar";
// import { loadProfiles, Profile, UserIdProfile } from "../../matrix/profile";
import { HomeServerApi } from "../../matrix/net/HomeServerApi";
import { imageToInfo } from "../session/common";

export class SelfInfoModel extends ViewModel {
  constructor(options) {
    super(options);
    const { session } = options;
    this._session = session;
    this._uid = this._session._sessionInfo.id;
    // this._client = new Client(this.platform);

    // const { client } = options;
    // this._client = client;
    this._client = new Client(this.platform);
    this._loading = false;
    this._profile = null;
    this._avatarUrl = undefined;
    this.load();
  }

  async load() {
    if (this._loading) {
      return;
    }
    try {
      this._loading = true;
      this.emitChange("loading");
      this._sessionInfos = await this.platform.sessionInfoStorage.get(
        this._uid
      );
      // console.log("this._sessionInfos---", this._sessionInfos);

      // const request = this.platform.request;
      // const homeserver = this.platform.config["defaultHomeServer"];
      // const hsApi = new HomeServerApi({ homeserver, request });
      this._profile = await this._session._hsApi
        .profile(this.userId)
        .response();
      this._avatarUrl = this._profile.avatar_url;
    } finally {
      this._loading = false;
      this.emitChange("loading");
    }
  }

  get loading() {
    return this._loading;
  }

  get userId() {
    return this._session?.userId;
  }

  get showUserId() {
    return this.userId.slice(0, this.userId.indexOf(":"));
  }

  get displayname() {
    return this._profile?.displayname || "-";
  }

  get avatarColorNumber() {
    return getIdentifierColorNumber(this._session.userId);
  }

  get avatarInitials() {
    return avatarInitials(this._session.userId);
  }

  get hasAvatar() {
    return !!this._avatarUrl;
  }

  get version() {
    const { updateService, version } = this.platform;
    // if (updateService) {
    //   return `${updateService.version} (${updateService.buildHash})`;
    // }
    if (version) {
      return version;
    }
    return this.i18n`development version`;
  }

  get offCancel() {
    return this.platform.config.off_login_register;
  }

  avatarUrl(size) {
    return getAvatarHttpUrl(
      this._avatarUrl,
      size,
      this.platform,
      this._session.mediaRepository
    );
  }

  /** @internal */
  async upload(fileBlob, fileName) {
    const request = this.platform.request;
    const homeserver = this.platform.config["defaultHomeServer"];
    const hsApi = new HomeServerApi({
      homeserver,
      request,
      accessToken: this._sessionInfos.accessToken,
    });
    const { content_uri } = await hsApi
      .uploadAttachment(fileBlob, fileName)
      .response();
    this.updateAvatar(content_uri);
  }

  openPanel(segment) {
    let path = this.navigation.path.until("session");
    path = path.with(this.navigation.segment(segment, true));
    this.navigation.applyPath(path);
  }

  gotoAvatar() {
    this.openPanel("self-avatar");
  }

  gotoQRCode() {
    this.openPanel("self-qrcode");
  }

  gotoMessage() {
    this.platform.global.navActive = 0;
    this.platform.openUrl(`#/session/${this._uid}`);
  }

  gotoContact() {
    this.platform.global.navActive = 1;
    this.platform.openUrl(`#/session/${this._uid}/contact`);
  }

  // 修改昵称
  gotoNickname() {
    this.openPanel("self-nickname");
  }
  
  // 修改密码
  gotoSetPassword(){
    this.openPanel("self-set-password");
  }

  gotoSettings() {
    window.location.href = `#/session/${this._uid}/settings`;
  }

  switchUser() {
    window.location.href = `#/`;
  }

  async logout() {
    window.location.href = `#/logout/${this._uid}`;
  }

  async selectAvatar() {
    this._avatarUrl = undefined;

    const file = await this.platform.openFile("image/*");
    if (!file || !file.blob.mimeType.startsWith("image/")) {
      // allow to clear the avatar by not selecting an image
      this.emitChange("hasAvatar");
      return;
    }
    let image = await this.platform.loadImage(file.blob);
    const limit = 800;
    if (image.maxDimension > limit) {
      const scaledImage = await image.scale(limit);
      image.dispose();
      image = scaledImage;
    }
    this.upload(image.blob, file.name);
  }

  async updateAvatar(value) {
    const request = this.platform.request;
    const homeserver = this.platform.config["defaultHomeServer"];
    const hsApi = new HomeServerApi({
      homeserver,
      request,
      accessToken: this._sessionInfos.accessToken,
    });
    await hsApi.profileUpdateAvatar(this.userId, value).response();
    this._avatarUrl = value;
    this.emitChange("hasAvatar");
    // this.urlRouter.urlUntilSegment("self");
  }
}
