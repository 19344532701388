
import { TemplateView } from "./TemplateView";


export class SwitchView extends TemplateView {
  constructor(options) {
    super();
    this._options = options;
  }

  render(t) {
    return t.label(
      {
        className: "switch",
        onClick: this._options.onclick || (() => {}),
      },
      [
        t.input({
          type: "checkbox",
          disabled: this._options.disabled || false,
          checked: this._options.checked || false,
          onchange: this._options.onchange || (() => {}),
        }),
        t.span({ className: "slider round" }),
      ]
    );
  }
}
