import axios from "axios";
import IMLog from "../platform/web/IMLog";

const service = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 10000,
  // withCredentials: true,
});

// 拦截请求
service.interceptors.request.use(
  async (config) => {
    const sessionInfo = await window.platform.sessionInfoStorage.get(
      window.platform.global?.sessionId
    );
    if (sessionInfo?.accessToken) {
      config.headers["Authorization"] = `Bearer ${sessionInfo?.accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 拦截响应
service.interceptors.response.use(
  // 响应成功进入第1个函数，该函数的参数是响应对象
  (response) => {
    const { data } = response;
    if (data?.code === undefined) return Promise.resolve(data);
    // 请求成功
    if (data && data.code === 0) return Promise.resolve(data);
    // token失效
    if (data && (data.code === 401 || data.code === 502)) {
      // 代码不要往后执行了
      return Promise.resolve({ ...data });
    }

    // 请求失败统一提示错误
    if (data && data.msg) {
      return Promise.resolve({ ...data });
    }
    return Promise.reject(response);
  },
  async (error) => {
    const { response } = error;
    IMLog.apiError({
      err_userid: window.platform.global?.userId || "",
      err_api_url: error.config.url,
      err_content: `${error.code}:${error.message}`,
      err_mark: error.code,
      err_time: new Date().getTime(),
      err_user_system: navigator.userAgent,
      err_user_network: navigator.connection?.effectiveType,
    });
    if (response && response.data.code === 401) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default service;
