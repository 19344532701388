import { ViewModel } from "../ViewModel";
import { Client } from "../../matrix/Client.js";
import { HomeServerApi } from "../../matrix/net/HomeServerApi";
import {
  avatarInitials,
  getIdentifierColorNumber,
  getAvatarHttpUrl,
} from "../avatar";

export class UpdateAvatarModel extends ViewModel {
  constructor(options) {
    super(options);
    const { session } = options;
    this._session = session;
    this._uid = this._session._sessionInfo.id;

    this._client = new Client(this.platform);
    this._loading = false;
    // this._closeUrl = this.urlRouter.urlUntilSegment("self");
    this._closeUrl = this.urlRouter.urlForSegment("self");
    this._error = null;
    this._avatarUrl = undefined;
    this._avatarScaledBlob = null;
    this._avatarFileName = null;
    this._btnLoading = false;
    this.load();
  }

  async load() {
    if (this._loading) {
      return;
    }
    try {
      this._loading = true;
      this.emitChange("loading");

      this._profile = await this._session._hsApi
        .profile(this.userId)
        .response();

      this._avatarUrl = getAvatarHttpUrl(
        this._profile.avatar_url,
        128,
        this.platform,
        this._session.mediaRepository
      );
    } finally {
      this._loading = false;
      this.emitChange("loading");
    }
  }

  get loading() {
    return this._loading;
  }

  get closeUrl() {
    return this._closeUrl;
  }

  get displayname() {
    return this._profile.displayname;
  }

  get userId() {
    return this._session?.userId;
  }

  get avatarColorNumber() {
    return getIdentifierColorNumber(this._session.userId);
  }

  get avatarInitials() {
    return avatarInitials(this._session.userId);
  }
  
  get hasAvatar() {
    return !!this.avatarUrl();
  }

  get btnLoading() {
    return this._btnLoading;
  }

  get error() {
    return this._error;
  }

  avatarUrl() {
    return this._avatarScaledBlob?.url || this._avatarUrl;
  }

  // 选择图片
  async selectAvatar() {
    if (!this.platform.hasReadPixelPermission()) {
      alert(
        "Please allow canvas image data access, so we can scale your images down."
      );
      return;
    }
    if (this._avatarScaledBlob) {
      this._avatarScaledBlob.dispose();
    }
    this._avatarScaledBlob = undefined;
    this._avatarFileName = undefined;
    this._avatarInfo = undefined;

    const file = await this.platform.openFile("image/*");
    if (!file || !file.blob.mimeType.startsWith("image/")) {
      // allow to clear the avatar by not selecting an image
      this.emitChange("hasAvatar");
      return;
    }
    let image = await this.platform.loadImage(file.blob);
    const limit = 800;
    if (image.maxDimension > limit) {
      const scaledImage = await image.scale(limit);
      image.dispose();
      image = scaledImage;
    }
    this._avatarScaledBlob = image.blob;
    // this._avatarInfo = imageToInfo(image);
    this._avatarFileName = file.name;
    this.emitChange("hasAvatar");
  }

  setError(value) {
    this._error = value;
    this.emitChange("error");
  }

  // 修改自己头像
  async handleSaveAvatar() {
    if (!this._avatarScaledBlob) {
      this.setError("请先选择图片！");
      return;
    }

    if (this._btnLoading) {
      return;
    }
    this._btnLoading = true;
    this.emitChange("btnLoading");

    const { content_uri } = await this._session._hsApi
      .uploadAttachment(this._avatarScaledBlob, this._avatarFileName)
      .response();

    await this._session._hsApi
      .profileUpdateAvatar(this.userId, content_uri)
      .response();

    //
    location.href = this.closeUrl;
  }
}
