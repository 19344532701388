/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { ViewModel } from "../../ViewModel";
import { RoomType } from "../../../matrix/room/common";
import {
  avatarInitials,
  getIdentifierColorNumber,
  getAvatarHttpUrl,
} from "../../avatar";
import { HomeServerApi } from "../../../matrix/net/HomeServerApi";
import { EventType } from "../../../utils/event";
// import { makeTxnId } from "../../../matrix/common";

export class MemberDetailsViewModel extends ViewModel {
  constructor(options) {
    super(options);
    this._observableMember = options.observableMember;
    this._mediaRepository = options.mediaRepository;
    this._member = this._observableMember.get();
    this._isEncrypted = options.isEncrypted;
    this._powerLevelsObservable = options.powerLevelsObservable;
    this._session = options.session;
    this._room = options.room;
    this.track(
      this._powerLevelsObservable.subscribe(() => this._onPowerLevelsChange())
    );
    this.track(this._observableMember.subscribe(() => this._onMemberChange()));
    // this._getRemark();
    this._customName = "";
    this._showLeaveDialog = false;

    this._loading = false;
    this.load();
  }

  get name() {
    return this._member.name;
  }
  get userId() {
    return this._member.userId;
  }

  get showUserId() {
    return this._member.userId.slice(0, this._member.userId.indexOf(":"));
  }

  get currentUserId() {
    return this._session?.userId;
  }

  get type() {
    return "member-details";
  }
  get shouldShowBackButton() {
    return false;
  }
  // get previousSegmentName() { return "members"; }
  // get previousSegmentName() { return false }
  get hideCloseButton() {
    return true;
  }

  get role() {
    if (this.powerLevel >= 100) {
      return this.i18n`Admin`;
    } else if (this.powerLevel >= 50) {
      return this.i18n`Moderator`;
    } else if (this.powerLevel === 0) {
      return this.i18n`Default`;
    } else {
      return this.i18n`Custom (${this.powerLevel})`;
    }
  }

  get roomId() {
    return this.navigation.path.get("room").value;
  }
  _onMemberChange() {
    this._member = this._observableMember.get();
    this.emitChange("member");
  }

  _onPowerLevelsChange() {
    this.emitChange("role");
  }

  async load() {
    if (this._loading) {
      return;
    }
    try {
      this._loading = true;
      this.emitChange("loading");

      this._sessionInfo = await this.platform.sessionInfoStorage.get(
        this._session._sessionInfo.id
      );

      const request = this.platform.request;
      const homeserver = this.platform.config["defaultHomeServer"];
      const hsApi = new HomeServerApi({
        homeserver,
        request,
        accessToken: this._sessionInfo.accessToken,
      });
      const data = await hsApi
        .roomsTags(this.currentUserId, this.roomId, {})
        .response();

      this._customName = data?.tags?.custom_name?.text;
      // console.log("data", this._customName);

      this.emitChange("customName");

      const pushrulesData = await hsApi.pushrulesAll().response();
      const { override } = pushrulesData?.global || {};
      this._disturbStatus =
        override.findIndex(
          (i) => i.rule_id === this.roomId && i.actions.includes("dont_notify")
        ) !== -1;

      console.log("this._disturbStatus------", this._disturbStatus);
      this.emitChange("disturbStatus");
    } finally {
      this._loading = false;
      this.emitChange("loading");
    }
  }

  get customName() {
    return this._customName;
  }

  get avatarLetter() {
    return avatarInitials(this.name);
  }

  get avatarColorNumber() {
    return getIdentifierColorNumber(this.userId);
  }

  avatarUrl(size) {
    return getAvatarHttpUrl(
      this._member.avatarUrl,
      size,
      this.platform,
      this._mediaRepository
    );
  }

  get avatarTitle() {
    return this.name;
  }

  get isEncrypted() {
    return this._isEncrypted;
  }

  get powerLevel() {
    return this._powerLevelsObservable.get()?.getUserLevel(this._member.userId);
  }

  get linkToUser() {
    return `https://matrix.to/#/${encodeURIComponent(this._member.userId)}`;
  }

  get showMessage() {
    return this.platform.global.navActive === 1;
  }

  get showLeaveDialog() {
    return this._showLeaveDialog;
  }

  get showClearHistory() {
    return this._showClearHistory;
  }

  get disturbStatus() {
    return this._disturbStatus;
  }

  get notify() {
    return this._notify;
  }

  back() {
    if (this.platform.global.navActive === 1) {
      let path = this.navigation.path.until("session");
      path = path.with(this.navigation.segment("contact", true));
      this.navigation.applyPath(path);
      return;
    }
    const path = this.navigation.path.until("room");
    this.navigation.applyPath(path);
  }

  async openDirectMessage() {
    const room = this._session.findDirectMessageForUserId(this.userId);
    let roomId = room?.id;
    if (!roomId) {
      const roomBeingCreated = await this._session.createRoom({
        type: RoomType.DirectMessage,
        invites: [this.userId],
      });
      roomId = roomBeingCreated.id;
    }
    this.navigation.push("room", roomId);
  }

  openPanel(segment) {
    let path = this.navigation.path.until("room");
    path = path.with(this.navigation.segment("right-panel", true));
    path = path.with(this.navigation.segment(segment, this.userId));
    this.navigation.applyPath(path);
  }

  showNotify(txt = "", hideTime = 1500) {
    this._notify = txt;
    this.emitChange("notify");
    setTimeout(() => {
      this._notify = "";
      this.emitChange("notify");
    }, hideTime);
  }

  clearHistory(type = "show") {
    this._showClearHistory = type === "show" ? true : false;
    this.emitChange("showClearHistory");
  }

  // 清理消息
  async handleClearHistory() {
    this.clearHistory("hide");
    // const cli = MatrixClientPeg.get();

    const msgTypes = [
      EventType.RoomMessage,
      EventType.RoomEncryption,
      EventType.RoomMessageEncrypted,
      EventType.Sticker,
    ];
    const allThreads = this._room._timeline._remoteEntries.array.filter((t) =>
      msgTypes.includes(t.eventType)
    );

    const request = this.platform.request;
    const homeserver = this.platform.config["defaultHomeServer"];
    const hsApi = new HomeServerApi({
      homeserver,
      request,
      accessToken: this._sessionInfo.accessToken,
    });
    for (let i = 0; i < allThreads.length; i++) {
      const t = allThreads[i];
      try {
        const txnId = "m" + new Date().getTime() + "." + i;
        await hsApi.redact(this.roomId, t.id, txnId, {});
      } catch (e) {
        console.error(e);
      }
    }

    // 提示
    this.showNotify("消息清理完成");
  }

  // 免打扰
  async doNotDisturb() {
    // console.log("current----", this.disturbStatus);
    const request = this.platform.request;
    const homeserver = this.platform.config["defaultHomeServer"];
    const hsApi = new HomeServerApi({
      homeserver,
      request,
      accessToken: this._sessionInfo.accessToken,
    });

    if (this.disturbStatus) {
      await hsApi.pushrulesOverrideDel(this.roomId, {}).response();
      this._disturbStatus = false;
    } else {
      await hsApi
        .pushrulesOverride(this.roomId, {
          conditions: [
            {
              kind: "event_match",
              key: "room_id",
              pattern: this.roomId,
            },
          ],
          actions: ["dont_notify"],
        })
        .response();
      this._disturbStatus = true;
    }
    this.emitChange("disturbStatus");

    window.document.dispatchEvent(
      new CustomEvent("disturb_status_update", {
        detail: {
          roomId: this.roomId,
          status: this._disturbStatus,
        },
      })
    );
  }

  // // 免打扰
  // async doNotDisturb() {
  //   console.log("doNotDisturb--->");
  //   const request = this.platform.request;
  //   const homeserver = this.platform.config["defaultHomeServer"];
  //   const hsApi = new HomeServerApi({
  //     homeserver,
  //     request,
  //     accessToken: this._sessionInfo.accessToken,
  //   });
  //   const data = await hsApi
  //     .pushrulesOverride(this.roomId, {
  //       conditions: [
  //         {
  //           kind: "event_match",
  //           key: "room_id",
  //           pattern: this.roomId,
  //         },
  //       ],
  //       actions: ["dont_notify"],
  //     })
  //     .response();
  // }

  // // 清理消息
  // async roomRedact() {
  //   // todo  获取eventId
  //   const eventId = this._room._timeline.getByEventId().token;
  //   console.log("getByEventId", eventId);

  //   const request = this.platform.request;
  //   const homeserver = this.platform.config["defaultHomeServer"];
  //   const hsApi = new HomeServerApi({
  //     homeserver,
  //     request,
  //     accessToken: this._sessionInfo.accessToken,
  //   });
  //   const txnId = "m" + new Date().getTime() + ".0";
  //   const data = await hsApi.redact(this.roomId, eventId, txnId, {}).response();
  // }

  LeaveRoom() {
    this._showLeaveDialog = true;
    this.emitChange("showLeaveDialog");
  }

  LeaveCancel() {
    this._showLeaveDialog = false;
    this.emitChange("showLeaveDialog");
  }

  async LeaveConfirm() {
    // const request = this.platform.request;
    // const homeserver = this.platform.config["defaultHomeServer"];
    // const hsApi = new HomeServerApi({
    //   homeserver,
    //   request,
    //   accessToken: this._sessionInfo.accessToken,
    // });
    const txnId = "m" + new Date().getTime() + ".0";
    await this._session._hsApi
      .fake_leave(this.currentUserId, this._room.id, {
        order: 0,
      })
      .response();
    await this._session._hsApi
      .send(this._room.id, "m.room.fake_leave", txnId, {})
      .response();

    // this._session._hsApi.leave(this._room.id).response();
    if (this.platform.global.navActive === 1) {
      this.platform.openUrl(`#/session/${this._sessionInfo.id}/contact`);
    } else {
      this.platform.openUrl(`#/session/${this._sessionInfo.id}`);
    }
  }
}
