import { TemplateView } from "../../general/TemplateView";
import { MemberListView } from "./MemberListView";
import { MemberTileView } from "./MemberTileView";
import { DialogView } from "../../general/DialogView";
import { ListView } from "../../general/ListView";

class FilterField extends TemplateView {
  render(t, options) {
    const clear = () => {
      filterInput.value = "";
      filterInput.blur();
      clearButton.blur();
      options.clear();
    };
    const filterInput = t.input({
      type: "text",
      placeholder: options?.label,
      "aria-label": options?.label,
      autocomplete: options?.autocomplete,
      enterkeyhint: "search",
      name: options?.name,
      onInput: (event) => options.set(event.target.value),
      onKeydown: (event) => {
        if (event.key === "Escape" || event.key === "Esc") {
          clear();
        }
      },
      onFocus: () => filterInput.select(),
    });
    const clearButton = t.button({
      onClick: clear,
      title: options.i18n`Clear`,
      "aria-label": options.i18n`Clear`,
    });
    return t.div({ className: "FilterField" }, [filterInput, clearButton]);
  }
}

// 成员管理
export class MembersMangeView extends TemplateView {
  updateDisplayname(e) {
    const value = e.target.value;
    this._displayname = value;
    console.log("e", value);
  }

  render(t, vm) {
    return t.main({ className: "member-manage-layer" }, [
      t.map(
        (vm) => vm.loading,
        (l, t) => {
          if (l) {
            return t.div({ className: "loading" }, "loading");
          }

          return t.div({ className: "content" }, [
            t.div({ className: "header" }, [
              t.div({
                className: "button-utility LoginView_back",
                onClick: () => vm.openPanel("details"),
              }),
              t.div(
                {
                  className: "title",
                },
                vm.i18n`Group member management`
              ),

              t.div({
                className: "button-utility create",
                onClick: () => vm.openPanel("member-list-select"),
              }),
              // t.div({
              //   className: "button-utility remove-icon",
              //   onClick: () => vm.openPanel("member-list-select"),
              // }),
            ]),

            t.view(
              new FilterField({
                i18n: vm.i18n,
                label: vm.i18n`Filter`,
                name: "filter",
                autocomplete: true,
                set: (query) => {
                  // scroll up if we just started filtering
                  if (vm.setFilter(query)) {
                    // roomList.scrollTop = 0;
                  }
                },
                clear: () => vm.clearFilter(),
              })
            ),


            t.view(new MemberListView(vm)),

            // 踢出用户提示
            t.map(
              (vm) => vm.showKickDialog,
              (show, t, vm) => {
                if (show) {
                  return new DialogView({
                    content: t.div({ className: "kick-layer" }, [
                      t.div(
                        { className: "desc" },
                        "踢掉用户将从此群移除该用户"
                      ),
                      t.label({ className: "checkbox-layer" }, [
                        t.input({
                          className: "checkbox",
                          type: "checkbox",
                          onChange: (v) => vm.handleKickCheckbox(v),
                        }),
                        t.span(
                          { className: "text" },
                          "删除此用户在群内所有消息记录"
                        ),
                      ]),
                    ]),
                    cancel: {
                      text: vm.i18n`Cancel`,
                      onClick: () => vm.hideKickDialog("hide"),
                    },
                    confirm: {
                      text: vm.i18n`Confirm`,
                      onClick: () => vm.handleKickAction(),
                    },
                  }).render(t);
                } else {
                  return t.div({ className: "hide" }, "");
                }
              }
            ),

            // 列表
          ]);
        }
      ),
    ]);
  }
}
