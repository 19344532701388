

import {TemplateView} from "../../general/TemplateView";
import {AvatarView} from "../../AvatarView.js";
// import {spinner} from "../../common.js";

export class MembersMangeSelectTileView extends TemplateView {
  render(t, vm) {
   
    const classes = {
      hide: (vm)=> vm.isHide,
      // active: (vm) => vm.isOpen,
      hidden: (vm) => vm.hidden,
    };

    return t.li({ className: classes, userId: vm.userId }, [
      t.label(
        {
          className: "user-item",
        },
        [
          t.input({
            className: "checkbox",
            type: "checkbox",
            name: "user-item",
            onChange:(v)=> vm.handleCheckbox(v),
          }),
          t.view(new AvatarView(vm, 52, "square"), {
            parentProvidesUpdates: true,
          }),
          t.div({ className: "description" }, [
            t.div({ className: { name: true } }, (vm) => vm.name),
          ]),
        ]
      ),
    ]);
  }

  update(value, props) {
    super.update(value);
    // update the AvatarView as we told it to not subscribe itself with parentProvidesUpdates
    this.updateSubViews(value, props);
  }
}
