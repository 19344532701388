

import { ViewModel } from "../../../ViewModel";
import { Client } from "../../../../matrix/Client.js";
import { HomeServerApi } from "../../../../matrix/net/HomeServerApi";

export class UpdateMemberNameModel extends ViewModel {
  constructor(options) {
    super(options);
    this._room = options.room;
    this._onRoomChange = this._onRoomChange.bind(this);
    this._room.on("change", this._onRoomChange);
    this._session = options.session;
    // console.log("this._session", this._session);

    this._client = new Client(this.platform);
    this._loading = false;
    this._closeUrl = this.urlRouter.urlForSegment("details");
    this._error = null;
    this._customName = "";
    this.sessionInfo = {};
    this.load();
  }

  get type() {
    return "member-update-remarks";
  }

  async load() {
    if (this._loading) {
      return;
    }
    try {
      this._loading = true;
      this.emitChange("loading");

      this._sessionInfo = await this.platform.sessionInfoStorage.get(
        this._session._sessionInfo.id
      );

      await this._getRemark();
    } finally {
      this._loading = false;
      this.emitChange("loading");
    }
  }

  get hideCloseButton() {
    return true;
  }

  async _getRemark() {
    const request = this.platform.request;
    const homeserver = this.platform.config["defaultHomeServer"];
    const hsApi = new HomeServerApi({
      homeserver,
      request,
      accessToken: this._sessionInfo.accessToken,
    });
    const data = await hsApi
      .roomsTags(this.currentUserId, this._room.id, {})
      .response();

    this._customName = data?.tags?.custom_name?.text;
    // console.log("data", this._customName);
    this.emitChange("customName");
  }

  get customName() {
    return this._customName;
  }

  get shouldShowBackButton() {
    return false;
  }

  get previousSegmentName() {
    return "member-details";
  }

  get loading() {
    return this._loading;
  }

  get closeUrl() {
    return this._closeUrl;
  }

  get displayname() {
    return this._room.name;
  }

  get userId() {
    // return this._session?.userId;
    return this.navigation.path.get("member-update-remarks").value;
  }

  get currentUserId() {
    return this._session?.userId;
  }

  get error() {
    return this._error;
  }

  _onRoomChange() {
    this.emitChange();
  }

  dispose() {
    super.dispose();
    this._room.off("change", this._onRoomChange);
  }

  goBack() {
    let path = this.navigation.path.until("room");
    path = path.with(this.navigation.segment("right-panel", true));
    path = path.with(this.navigation.segment("member", this.userId));
    this.navigation.applyPath(path);
  }

  openPanel(segment) {
    let path = this.navigation.path.until("room");
    path = path.with(this.navigation.segment("right-panel", true));
    path = path.with(this.navigation.segment(segment, true));
    this.navigation.applyPath(path);
  }

  setError(value) {
    this._error = value;
    this.emitChange("error");
  }

  async updateDisplayname(value) {
    if (!value) {
      this.setError(this.i18n`Please enter the remarks`);
      return;
    }
    // if (value === this.displayname) {
    //   this.setError(this.i18n`cannot same with username`);
    //   return;
    // }
    console.log("updateDisplayname", this._room.id, {
      name: value,
    });

    if (value.length >= 100) {
      this.setError(this.i18n`Length incorrect`);
      return;
    }


    const request = this.platform.request;
    const homeserver = this.platform.config["defaultHomeServer"];
    const hsApi = new HomeServerApi({
      homeserver,
      request,
      accessToken: this._sessionInfo.accessToken,
    });
    await hsApi
      .updateRemarkName(this.currentUserId, this._room.id, {
        text: value,
        order: 0,
      })
      .response();

     await this.platform.store.updateFriendNickName(this.userId, value);


    this.goBack();
  }
}
