

import {LazyListView} from "../../general/LazyListView";
import { ListView } from "../../general/ListView";
import {MemberTileView} from "./MemberTileView.js";

export class MemberListView extends ListView {
  constructor(vm) {
    super(
      {
        list: vm.memberTileViewModels,
        className: "MemberListView",
        i18n: vm.i18n,
        category: "group-manage",
        itemHeight: 40,
      },
      (tileViewModel) => new MemberTileView(tileViewModel)
    );
  }
}
