/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { ViewModel } from "../../ViewModel";
import {
  avatarInitials,
  getIdentifierColorNumber,
  getAvatarHttpUrl,
} from "../../avatar";
import { redactUserMessage } from "../../../api";
import { EventType } from "../../../utils/event";

// 群用户信息、操作页
export class RoomMemberOperateModel extends ViewModel {
  constructor(options) {
    super(options);
    this._room = options.room;
    this._session = options.session;
    this._powerLevels = options.powerLevelsObservable.get();

    this._loading = false;
    this._userName = null;
    this._userAvatar = "A";
    this._userId = "";
    this._showKickDialog = false;
    this._mute = true; // 被禁言
    this._showClearHistory = false;
    this._notify = "";
    this._showRemoveConfirmDialog = false;
    this._showAdminConfirmDialog = false;
    this._currentUserIsAdmin = false;
    this._delChatRecord = false;

    this._isLeave = false;
    this.load();
  }

  async load() {
    if (this._loading) {
      return;
    }

    try {
      this._loading = true;
      this.emitChange("loading");

      const userId = decodeURIComponent(
        this.navigation.path.get("member-operate")?.value
      );
      this._userId = userId;

      const observableMember = await this._room.observeMember(userId);
      if (observableMember?._value?._data?.membership !== "join") {
        this._isLeave = observableMember?._value?._data?.membership;
      }

      // 查询用户信息
      const userInfo = await this._session._hsApi.profile(userId).response();
      console.log("userId", userId, this._powerLevels._plEvent.content.users);
      this._userAvatar = userInfo?.avatar_url || userInfo.displayname;
      this._userName = userInfo.displayname;

      // 检查用户权限
      if (this._powerLevels._plEvent.content.users[userId] !== -1) {
        this._mute = false;
      }

      this._currentUserIsAdmin =
        this._powerLevels._plEvent.content.users[userId] >= 100;

      this.emitChange("mute");
    } finally {
      this._loading = false;
      this.emitChange("loading");
    }
  }

  get loading() {
    return this._loading;
  }
  get type() {
    return "member-operate";
  }

  get roomId() {
    return this._room.id;
  }

  get name() {
    return this._userName;
  }

  get showUserId() {
    return this._userId.slice(0, this._userId.indexOf(":"));
  }

  get isAdmin() {
    return this._powerLevels._myLevel >= 100;
  }

  get avatarLetter() {
    return avatarInitials(this.name);
  }

  get avatarColorNumber() {
    return getIdentifierColorNumber(this._room.avatarColorId);
  }

  get hideCloseButton() {
    return true;
  }

  // 查看用户是否管理员
  get currentUserIsAdmin() {
    return this._currentUserIsAdmin;
  }

  // 禁言
  get mute() {
    return this._mute;
  }

  // 用户是否在线 todo
  get online() {
    return false;
  }

  // 显示用户角色
  get currentUserRole() {
    return false;
  }

  get showKickDialog() {
    return this._showKickDialog;
  }

  get showClearHistory() {
    return this._showClearHistory;
  }

  get notify() {
    return this._notify;
  }

  get showRemoveConfirmDialog() {
    return this._showRemoveConfirmDialog;
  }

  get showAdminConfirmDialog() {
    return this._showAdminConfirmDialog;
  }

  get isLeave() {
    return this._isLeave;
  }

  // 头像
  avatarUrl(size) {
    return getAvatarHttpUrl(
      this._userAvatar,
      size,
      this.platform,
      this._room.mediaRepository
    );
  }

  get avatarTitle() {
    return this.name;
  }

  async openDirectMessage() {
    this.navigation.push("room", this.roomId);
  }

  back() {
    const path = this.navigation.path.until("room");
    this.navigation.applyPath(path);
  }

  dispose() {
    super.dispose();
  }

  openPanel(segment) {
    let path = this.navigation.path.until("room");
    path = path.with(this.navigation.segment("right-panel", true));
    path = path.with(this.navigation.segment(segment, true));
    this.navigation.applyPath(path);
  }

  hideDialog(key) {
    this[key] = false;
    this.emitChange(key.replace("_"));
  }

  showNotify(txt = "", hideTime = 1500) {
    this._notify = txt;
    this.emitChange("notify");
    setTimeout(() => {
      this._notify = "";
      this.emitChange("notify");
    }, hideTime);
  }

  // 删除用户消息
  clearHistory(type) {
    if (type === "hide") {
      this._showClearHistory = false;
      this.emitChange("showClearHistory");
      return;
    }
    this._showClearHistory = true;
    this.emitChange("showClearHistory");
  }

  // 确认清理
  async handleClearHistory() {
    if (this._loading) return;

    this._loading = true;

    // await redactUserMessage({
    //    room_id: this.roomId,
    //    user_id: this._userId,
    //  });

    const msgTypes = [
      EventType.RoomMessage,
      EventType.RoomEncryption,
      EventType.RoomMessageEncrypted,
      EventType.Sticker,
    ];
    const allThreads = this._room._timeline._remoteEntries.array.filter(
      (t) => msgTypes.includes(t.eventType) && !t.isRedacted
    );
    for (let i = 0; i < allThreads.length; i++) {
      const t = allThreads[i];
      const sender = t?.sender;
      // console.log("sender, userId", sender, userId, t);
      try {
        const txnId = "m" + new Date().getTime() + "." + i;
        if (sender === this._userId) {
          await this._session._hsApi.redact(this.roomId, t.id, txnId, {});
        }
      } catch (e) {
        console.error(e);
      }
    }

    this._showClearHistory = false;
    this._loading = false;
    this.emitChange("showClearHistory");
    this.showNotify(this.i18n`Operation success`);
  }

  showAdminDialog() {
    this._showAdminConfirmDialog = true;
    this.emitChange("showAdminConfirmDialog");
  }

  // 设置成管理员
  async handleRole() {
    if (this._loading) return;
    this._loading = true;

    const data = await this._session._hsApi
      .roomPowerLevels(this.roomId, {
        ...this._powerLevels._plEvent.content,
        users: {
          ...this._powerLevels._plEvent.content.users,
          [this._userId]: 100,
        },
      })
      .response();

    this._showAdminConfirmDialog = false;
    this._loading = false;
    this.emitChange("showAdminConfirmDialog");
    this.showNotify(this.i18n`Operation success`);
    setTimeout(() => {
      this.back();
    }, 1000);
  }

  // 禁言
  async handleMute() {
    this._loading = true;
    this.emitChange("loading");

    const data = await this._session._hsApi
      .roomPowerLevels(this.roomId, {
        ...this._powerLevels._plEvent.content,
        users: {
          ...this._powerLevels._plEvent.content.users,
          [this._userId]: -1,
        },
      })
      .response();
    console.log("dat--", data);
    if (data?.event_id) {
      // this._powerLevels._plEvent.content.users[this.userId] = 2
      this._mute = true;
      this.emitChange("mute");
    }

    this._loading = false;
    this.emitChange("loading");
  }

  // 取消禁言
  async handleUnMute() {
    this._loading = true;
    this.emitChange("loading");

    const data = await this._session._hsApi
      .roomPowerLevels(this.roomId, {
        ...this._powerLevels._plEvent.content,
        users: {
          ...this._powerLevels._plEvent.content.users,
          [this._userId]: 0,
        },
      })
      .response();

    console.log("dat--handleUnMute", data);
    if (data?.event_id) {
      // this._powerLevels._plEvent.content.users[this.userId] = 3;
      this._mute = false;
      this.emitChange("mute");
    }

    this._loading = false;
    this.emitChange("loading");
  }

  // 设置头衔
  async setTitle() {}

  async removeDialog() {
    this._showRemoveConfirmDialog = true;
    this.emitChange("showRemoveConfirmDialog");
  }

  // 移除box选择
  handleKickCheckbox(e) {
    const { checked } = e.target;
    this._delChatRecord = checked;
  }
  // 从此群移除
  async handleKick() {
    if (this._loading) return;
    this._loading = true;
    this._showKickDialog = false;
    this.emitChange("showKickDialog");
    
    const userId = this._userId;
    const roomId = this.roomId;
    if (!userId) return;
    await this._session._hsApi
      .roomKick(roomId, {
        user_id: userId,
        reason: "h5-action-room-remove",
      })
      .response();

    const action = () => {
      this._loading = false;
      this.showNotify(this.i18n`Operation success`);
      this.back();
    };
    // 如果勾选删除聊天记录
    if (!this._delChatRecord) {
      action();
      return;
    }
    await redactUserMessage({
      room_id: roomId,
      user_id: userId,
    });
    action();
  }

  // 邀请
  async handleInvites(){
     if (this._loading) return;
    this._loading = true;

    await this._session._hsApi
      .roomInvite(this.roomId, {
        user_id: this._userId,
        reason: "Invites",
      })
      .response();
  
      this._loading = false;
      this._isLeave = "invites";
      this.emitChange("isLeave");
       this.showNotify(this.i18n`Operation success`);
  }

  // 从此群封禁
}
