import { TemplateView } from "../../general/TemplateView";

export class UpdateGroupView extends TemplateView {
  updateDisplayname(e) {
    const value = e.target.value;
    this._displayname = value;
    console.log("e", value);
  }

  render(t, vm) {
    return t.main({ className: "update-nickname-layer" }, [
      t.map(
        (vm) => vm.loading,
        (l, t) => {
          if (l) {
            return t.div({ className: "loading" }, "loading");
          }

          return t.div({ className: "content" }, [
            t.div({ className: "header" }, [
              t.a({
                className: "button-utility close-middle",
                href: vm.closeUrl,
              }),
            ]),
            t.div(
              {
                className: "title",
              },
              "修改群聊名称"
            ),

            t.div(
              { className: "input" },
              t.input({
                id: "username",
                type: "text",
                placeholder: vm.i18n`Enter name`,
                value: (vm) => vm.displayname,
                onInput: (e) => this.updateDisplayname(e),
                // onChange: this.updateDisplayname,
              })
            ),

            t.div(
              { className: "btn" },
              t.button(
                {
                  className: "button-action primary",
                  type: "submit",
                  onClick: () => vm.updateDisplayname(this._displayname),
                },
                vm.i18n`Done`
              )
            ),
            t.map(
              (vm) => vm.error,
              (e, t) => t.p({ className: "error" }, e)
            ),
          ]);
        }
      ),
    ]);
  }
}
