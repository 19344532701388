import { ViewModel } from "../ViewModel";
import { Client } from "../../matrix/Client.js";
import { avatarInitials, getIdentifierColorNumber } from "../avatar";
import QRCode from "qrcode";
import { HomeServerApi } from "../../matrix/net/HomeServerApi";

// 我的页面
export class QRCodeModel extends ViewModel {
  constructor(options) {
    super(options);
     const { session } = options;
     this._session = session;
     this._uid = this._session._sessionInfo.id;
     
    // this._client = new Client(this.platform);
    this._loading = false;
    this._closeUrl = this.urlRouter.urlForSegment("self");
    this._QRCode = null;
    this.load()
  }

  async load() {
    if (this._loading) {
      return;
    }
    try {
      this._loading = true;
      this.emitChange("loading");
      // this._sessionInfos = await this.platform.sessionInfoStorage.get(
      //    this._uid
      //  );

      // const request = this.platform.request;
      // const homeserver = this.platform.config["defaultHomeServer"];
      // const hsApi = new HomeServerApi({ homeserver, request });
      this._profile = await this._session._hsApi.profile(this.userId).response();

      let QRCodeText = `${location.origin}/#/friend/${encodeURIComponent(
        this.userId
      )}`;
      this._QRCode = await this.generateQR(QRCodeText);
    } finally {
      this._loading = false;
      this.emitChange("loading");
    }
  }

  async generateQR(text) {
    try {
      return await QRCode.toDataURL(text);
    } catch (err) {
      console.error(err);
    }
  }

  get closeUrl() {
    return this._closeUrl;
  }

  get displayname() {
    return this._profile.displayname;
  }

  get loading() {
    return this._loading;
  }

  get QRCode(){
    return this._QRCode;
  }

  async getsessionInfo() {
    const data = await this.platform.sessionInfoStorage.getAll();
    // console.log("data", data);
    return data[0];
  }

  get userId() {
    return this._session?.userId;
  }

  get avatarColorNumber() {
    return getIdentifierColorNumber(this._session.userId);
  }

  get avatarInitials() {
    return avatarInitials(this._session.userId);
  }
}
