/*
Copyright 2020 Bruno Windels <bruno@windels.cloud>
Copyright 2020 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

// import {RecordRequester, ReplayRequester} from "./matrix/net/request/replay";
import {RootViewModel} from "../../domain/RootViewModel.js";
import {createNavigation, createRouter} from "../../domain/navigation/index";
import {I18N} from "../../i18n/main";
import {KgUtils, initRequestUrl} from "../../KgUtils";
import lang from "./public/i18n/zh_Hans";
import IMLog from "./IMLog.js";
// Don't use a default export here, as we use multiple entries during legacy build,
// which does not support default exports,
// see https://github.com/rollup/plugins/tree/master/packages/multi-entry
export async function main(platform) {
    try {
      // to replay:
      // const fetchLog = await (await fetch("/fetchlogs/constrainterror.json")).json();
      // const replay = new ReplayRequester(fetchLog, {delay: false});
      // const request = replay.request;

      // to record:
      // const recorder = new RecordRequester(createFetchRequest(clock.createTimeout));
      // const request = recorder.request;
      // window.getBrawlFetchLog = () => recorder.log();

      await new I18N().setLang("zh", lang);
      await platform.init();
      // not Mobile  redirect  web
      // if (
      //   !navigator.userAgent.match(
      //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      //   ) &&
      //   platform.config?.webUrl
      // ) {
      //   platform.openUrl(platform.config?.webUrl);
      //   return;
      // }
      const navigation = createNavigation();
      platform.setNavigation(navigation);
      const kgUtils = new KgUtils();
      kgUtils.setRemoteConfigUrls(platform.config.remote_config_urls);
      await kgUtils.getRemoteConfig(platform.config.remote_config_name);
      platform.remoteConfig = kgUtils.config;
      initRequestUrl(platform.remoteConfig, platform);
      const urlRouter = createRouter({ navigation, history: platform.history });
      urlRouter.attach();
      const vm = new RootViewModel({
        platform,
        // the only public interface of the router is to create urls,
        // so we call it that in the view models
        urlRouter: urlRouter,
        navigation,
      });
      await vm.load();
      platform.createAndMountRootView(vm);
      
    } catch(err) {
      let content = `${err?.message}:${err?.stack}`;
      if(err?.message === undefined){
        content = JSON.stringify(err)
      }
      IMLog.debugLog({
        key: "h5-main",
        mark: location.href,
        content: content,
        time: new Date().getTime(),
        user_system: navigator.userAgent,
        user_network: navigator.connection?.effectiveType,
        user_id: window.platform.global?.userId || "",
      });
     
      console.error(`${err.message}:\n${err.stack}`);
    }
}
