
import {AvatarView} from "../../AvatarView.js";
import { SwitchView } from "../../general/SwitchView.js";
import {TemplateView} from "../../general/TemplateView";
import { DialogView } from "../../general/DialogView";

export class MemberDetailsAddFriendView extends TemplateView {
  render(t, vm) {
    return t.div({ className: "content-list member-detail" }, [
      t.div(
        {
          className: {
            header: true,
            RightPanelView_buttons: true,
          },
        },
        [
          t.button({
            className: {
              back: true,
              "button-utility": true,
            },
            onClick: () => vm.back(),
          }),
        ]
      ),
      t.div({ className: "user-avatar" }, [
        t.view(new AvatarView(vm, 128)),
        t.div(
          {
            className: "info",
          },
          [
            t.map(
              (vm) => vm.displayname,
              (name, t) => t.div({ className: "nickname remark" }, name)
            ),
            // t.div({ className: "nickname" }, vm.displayname),
            t.div({ className: "nickname" }, vm.showUserId),
          ]
        ),
      ]),

      t.div(
        {
          className: "send-message",
          onClick: () => vm.handleAddFriend(),
        },
        vm.i18n`Start new chat`
      ),

      //  提示
      t.map(
        (vm) => vm.notify,
        (n, t) => {
          if (n) {
            return t.div(
              {
                className: "notify show",
              },
              n
            );
          }
        }
      ),

      // 清理消息
      t.map(
        (vm) => vm.showClearHistory,
        (show, t, vm) => {
          if (show) {
            return new DialogView({
              content: vm.i18n`Confirm clear history`,
              cancel: {
                text: vm.i18n`Cancel`,
                onClick: () => vm.clearHistory("hide"),
              },
              confirm: {
                text: vm.i18n`Confirm`,
                onClick: () => vm.handleClearHistory(),
              },
            }).render(t);
          } else {
            return t.div({ className: "hide" }, "");
          }
        }
      ),

      // 弹窗
      t.map(
        (vm) => vm.showLeaveDialog,
        (show, t, vm) => {
          if (show) {
            return new DialogView({
              content: vm.i18n`Are you sure you want to delete your friends`,
              cancel: {
                text: vm.i18n`Cancel`,
                onClick: () => vm.LeaveCancel(),
              },
              confirm: {
                text: vm.i18n`Confirm`,
                onClick: () => vm.LeaveConfirm(),
              },
            }).render(t);
          } else {
            return t.div({ className: "hide" }, "");
          }
        }
      ),
    ]);
  }

  _row(props) {
    const {
      t,
      label,
      content,
      extraClass = "",
      arrow = true,
      clickFun = () => {},
    } = props;
    return t.div({ className: `row ${extraClass}`, onClick: clickFun }, [
      t.div({ className: "label" }, label),
      t.div({ className: "content" }, content),
      t.div({ className: `${arrow ? "arrow" : "hide"}` }),
    ]);
  }

  _hr(t) {
    return t.div({ className: "hr" });
  }


 
}
