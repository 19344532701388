/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { HomeServerApi } from "../../../matrix/net/HomeServerApi";
import { ViewModel } from "../../ViewModel";
import {
  avatarInitials,
  getIdentifierColorNumber,
  getAvatarHttpUrl,
} from "../../avatar";
import { EventType } from "../../../utils/event";
// import { MappedList } from "../../../../src/observable/list/MappedList";

export class RoomDetailsViewModel extends ViewModel {
  constructor(options) {
    super(options);
    this._room = options.room;
    this._session = options.session;
    this._onRoomChange = this._onRoomChange.bind(this);
    this._room.on("change", this._onRoomChange);
    this._powerLevels = options.powerLevelsObservable.get();
    // console.log("this._powerLevels-----", this._powerLevels);
    this._showLeaveDialog = false;

    this._dialogLoading = false;
    this._topic = "";
    this._showMuteDialog = false;
    this._notify = false;
    this._isServerNotice = true;

    this._loading = false;
    this.load();
  }

  async load() {
    if (this._loading) {
      return;
    }
    try {
      this._loading = true;
      this.emitChange("loading");

      this._sessionInfo = await this.platform.sessionInfoStorage.get(
        this._session._sessionInfo.id
      );


      const topicMap = await this._room.observeStateType("m.room.topic")
      // console.log(" this._session------>", this._session, this._room, topicMap);
      this._topic = topicMap._values.get("")?.content?.topic || ''

      // const request = this.platform.request;
      // const homeserver = this.platform.config["defaultHomeServer"];
      // const hsApi = new HomeServerApi({
      //   homeserver,
      //   request,
      //   accessToken: this._sessionInfo.accessToken,
      // });
      // const data = await hsApi.messages(this.roomId, { dir: "b" }).response();
      // const topic = data.chunk.find((i) => i.type === "m.room.topic");
      // if (topic) {
      //   this._topic = topic?.content?.topic || "";
      // }


      this._eventsDefault = this._powerLevels._plEvent?.content?.events_default;
      const pushrulesData = await this._session._hsApi.pushrulesAll()
        .response();
      const { override } = pushrulesData?.global || {};
      this._disturbStatus =
        override.findIndex(
          (i) => i.rule_id === this.roomId && i.actions.includes("dont_notify")
        ) !== -1;
      // console.log("pushrulesData", pushrulesData);

      // this._powerLevels = await this._room.observePowerLevels();

        const tags = this._room._summary.data.tags
        if(tags && tags['m.server_notice']){
          this._isServerNotice = true;
        }else{
          this._isServerNotice = false;
        }
    } finally {
      this._loading = false;
      this.emitChange("loading");
    }
  }

  get loading() {
    return this._loading;
  }

  get notify() {
    return this._notify;
  }

  get type() {
    return "room-details";
  }

  get shouldShowBackButton() {
    return false;
  }

  get hideCloseButton() {
    return true;
  }

  get previousSegmentName() {
    return false;
  }

  get roomId() {
    return this._room.id;
  }

  get canonicalAlias() {
    return this._room.canonicalAlias;
  }

  get name() {
    return this._room.name;
  }

  get isEncrypted() {
    return !!this._room.isEncrypted;
  }

  get memberCount() {
    return this._room.joinedMemberCount;
  }

  get isAdmin() {
    return this._powerLevels._myLevel >= 100;
  }

  get avatarLetter() {
    return avatarInitials(this.name);
  }

  get avatarColorNumber() {
    return getIdentifierColorNumber(this._room.avatarColorId);
  }

  get eventsDefault() {
    return this._eventsDefault;
  }

  get showLeaveDialog() {
    return this._showLeaveDialog;
  }

  get showMuteDialog() {
    return this._showMuteDialog;
  }

  get showClearHistory() {
    return this._showClearHistory;
  }

  get topic() {
    return this._topic;
  }

  get isServerNotice(){
    return this._isServerNotice;
  }

  get disturbStatus() {
    return this._disturbStatus;
  }

  avatarUrl(size) {
    return getAvatarHttpUrl(
      this._room.avatarUrl,
      size,
      this.platform,
      this._room.mediaRepository
    );
  }

  get avatarTitle() {
    return this.name;
  }

  async openDirectMessage() {
    this.navigation.push("room", this.roomId);
  }

  back() {
    if (this.platform.global.navActive === 1) {
      let path = this.navigation.path.until("session");
      path = path.with(this.navigation.segment("contact", true));
      this.navigation.applyPath(path);
      return;
    }
    const path = this.navigation.path.until("room");
    this.navigation.applyPath(path);
  }

  _onRoomChange() {
    this.emitChange();
  }

  dispose() {
    super.dispose();
    this._room.off("change", this._onRoomChange);
  }

  openPanel(segment) {
    let path = this.navigation.path.until("room");
    path = path.with(this.navigation.segment("right-panel", true));
    path = path.with(this.navigation.segment(segment, true));
    this.navigation.applyPath(path);
  }

  showNotify(txt = "", hideTime = 1500) {
    this._notify = txt;
    this.emitChange("notify");
    setTimeout(() => {
      this._notify = "";
      this.emitChange("notify");
    }, hideTime);
  }

  clearHistory(type = "show") {
    this._showClearHistory = type === "show" ? true : false;
    this.emitChange("showClearHistory");
  }

  // 清理消息
  async handleClearHistory() {
    this.clearHistory("hide");
    // const cli = MatrixClientPeg.get();

    const msgTypes = [
      EventType.RoomMessage,
      EventType.RoomEncryption,
      EventType.RoomMessageEncrypted,
      EventType.Sticker,
    ];
    const allThreads = this._room._timeline._remoteEntries.array.filter(
      (t) => msgTypes.includes(t.eventType) && !t.isRedacted
    );

    // const request = this.platform.request;
    // const homeserver = this.platform.config["defaultHomeServer"];
    // const hsApi = new HomeServerApi({
    //   homeserver,
    //   request,
    //   accessToken: this._sessionInfo.accessToken,
    // });
    for (let i = 0; i < allThreads.length; i++) {
      const t = allThreads[i];
      try {
        const txnId = "m" + new Date().getTime() + "." + i;
        await this._session._hsApi.redact(this.roomId, t.id, txnId, {});
      } catch (e) {
        console.error(e);
      }
    }

    // 提示
    this.showNotify("消息清理完成");
  }

  // 免打扰
  async doNotDisturb() {
    // console.log("current----", this.disturbStatus);
    const request = this.platform.request;
    const homeserver = this.platform.config["defaultHomeServer"];
    const hsApi = new HomeServerApi({
      homeserver,
      request,
      accessToken: this._sessionInfo.accessToken,
    });

    if (this.disturbStatus) {
      await hsApi.pushrulesOverrideDel(this.roomId, {}).response();
      this._disturbStatus = false;
    } else {
      await hsApi
        .pushrulesOverride(this.roomId, {
          conditions: [
            {
              kind: "event_match",
              key: "room_id",
              pattern: this.roomId,
            },
          ],
          actions: ["dont_notify"],
        })
        .response();
      this._disturbStatus = true;
    }
    this.emitChange("disturbStatus");
  }

  confirmMuteDialog() {
    this._showMuteDialog = true;
    this.emitChange("showMuteDialog");
  }

  confirmMuteDialogCancel() {
    this._showMuteDialog = false;
    this.emitChange("showMuteDialog");
  }

  // 群禁言
  async muteDialogCancelConfirm() {
    const setNum = this._eventsDefault ? 0 : 3;
    const request = this.platform.request;
    const homeserver = this.platform.config["defaultHomeServer"];
    const hsApi = new HomeServerApi({
      homeserver,
      request,
      accessToken: this._sessionInfo.accessToken,
    });
    await hsApi.roomPowerLevels(this.roomId, {
      ...this._powerLevels._plEvent.content,
      events_default: setNum,
    });

    this.confirmMuteDialogCancel();

    this._eventsDefault = setNum;
    this.emitChange("eventsDefault");

    // 提示
    this.showNotify(setNum ? "成功禁言群" : "成功取消群禁言");
  }

  LeaveGroup() {
    this._showLeaveDialog = true;
    this.emitChange("showLeaveDialog");
  }

  DeleteGroup() {
    this._showLeaveDialog = true;
    this.emitChange("showLeaveDialog");
  }

  LeaveCancel() {
    this._showLeaveDialog = false;
    this.emitChange("showLeaveDialog");
  }

  async LeaveConfirm() {
    // const request = this.platform.request;
    // const homeserver = this.platform.config["defaultHomeServer"];
    // const hsApi = new HomeServerApi({
    //   homeserver,
    //   request,
    //   accessToken: this._sessionInfo.accessToken,
    // });

    this._session._hsApi
      .roomPowerLevels(this._room.id, {
        ...this._powerLevels._plEvent.content,
        users: {
          ...this._powerLevels._plEvent.content.users,
          [this._session.userId]: 0,
        },
      })
      .response();

    await this._session._hsApi.leave(this._room.id).response();
    // if (this.isAdmin) {
    //   await this._session._hsApi.forget(this._room.id).response();
    // }
    // this.platform.openUrl(`#/session/${this._sessionInfo.id}`);
    if (this.platform.global.navActive === 1) {
      this.platform.openUrl(`#/session/${this._sessionInfo.id}/contact`);
    } else {
      this.platform.openUrl(`#/session/${this._sessionInfo.id}`);
    }
  }
}
