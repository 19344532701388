import { TemplateView } from "../general/TemplateView";
import {FooterView} from "../general/FooterView"
import { LoadingView } from "../general/LoadingView";
import {AvatarView} from "../AvatarView";
import {StaticView} from "../general/StaticView";

export class SelfInfoView extends TemplateView {
  render(t, vm) {
    
    return t.main({ className: "self" }, [
      t.map(
        (vm) => vm.loading,
        (l, t) => {
          // if (l) {
          //   return t.view(new LoadingView(vm.i18n`Loading`));
          // }

       
          let version = vm.version;

          const row = (t, label, content, extraClass = "", arrow = true, clickFun= ()=> {}) => {
            return t.div(
              { className: `row ${extraClass}`, onClick: clickFun },
              [
                t.div({ className: "label" }, label),
                t.div({ className: "content" }, content),
                t.div({ className: `${arrow ? "arrow" : "hide"}` }),
              ]
            );
          };

          const settingNodes = [];

          settingNodes.push(
            row(
              t,
              vm.i18n`Avatar`,
              t.div(
                { className: `avatar_box usercolor${vm.avatarColorNumber}` },
                [
                  t.button(
                    {
                      type: "button",
                      className: "CreateRoomView_selectAvatar",
                      onClick: () => vm.gotoAvatar(),
                    },
                    t.mapView(
                      (vm) => vm.hasAvatar,
                      (hasAvatar) => {
                        if (hasAvatar) {
                          return new AvatarView(vm, 52);
                        } else {
                          return new StaticView(undefined, (t) => {
                            return t.div(
                              {
                                className: `avatar size-52 usercolor${vm.avatarColorNumber}`,
                              },
                              vm.avatarInitials
                            );
                          });
                        }
                      }
                    )
                  ),
                ]
              ),
              // t.div(
              //   { className: `avatar usercolor${vm.avatarColorNumber}` },
              //   [
              //     t.form({className: "Edit_avatar form"}, vm.avatarInitials)
              //   ]
              //   // (vm) => vm.avatarInitials
              // ),
              "",
              true,
              () => {}
            ),
            t.div({ className: "hr" }),

            row(t, "ID", vm.showUserId, "", false),
            t.div({ className: "hr" }),

            t.map(
              (vm) => vm.displayname,
              (name, t, vm) =>
                t.div({ className: `row `, onClick: () => vm.gotoNickname() }, [
                  t.div({ className: "label" }, vm.i18n`Nickname`),
                  t.div({ className: "content" }, name),
                  t.div({ className: `arrow` }),
                ])
            ),
            t.div({ className: "hr" }),

            row(
              t,
              vm.i18n`QR Code`,
              t.div({ className: "qrcode" }),
              "",
              true,
              () => {
                vm.gotoQRCode();
              }
            ),
            t.div({ className: "hr" }),
            row(t, vm.i18n`Change Password`, "", "", true, () =>
              vm.gotoSetPassword()
            ),
            t.div({ className: "hr" }),
            row(t, vm.i18n`Version`, version, "", false),
            t.div({ className: "hr" }),

            // row(t, vm.i18n`Set up`, "", "", true, () => vm.gotoSettings()),
            // t.div({ className: "hr" }),
            // row(t, vm.i18n`Settings`, "", "", true, () => vm.gotoSettings()),
            // row(t, vm.i18n`Switch user`, "", "", true, () => vm.switchUser()),
            // t.div({ className: "hr" }),

            t.map(
              (vm) => vm.offCancel,
              (off, t, vm) => {
                if (!off) {
                  return t.div({}, [
                    row(t, vm.i18n`Sign out`, "", "", true, () => vm.logout()),
                    t.div({ className: "hr" }),
                  ]);
                }
              }
            )

            //
          );

          let footer = new FooterView([
            FooterView.option(vm.i18n`messages`, ()=> vm.gotoMessage()).setIcon('message'),
            FooterView.option(vm.i18n`address list`, ()=> vm.gotoContact()).setIcon('txl'),
            FooterView.option(vm.i18n`mine`, ()=> {}).setIcon('me').setDestructive()
          ])
          
          return t.div({ className: "content" }, [
            t.div({ className: "mine-body" }, settingNodes),
            footer.render(t),
          ]);
        }
      ),
    ]);
  }

  // update(value, props) {
  //   console.log("value, props", value, props);
  //   super.update(value);
  //   // update the AvatarView as we told it to not subscribe itself with parentProvidesUpdates
  //   this.updateSubViews(value, props);
  // }
}
