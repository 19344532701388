import { ViewModel } from "../../ViewModel";
import { RoomTileViewModel } from "./RoomTileViewModel.js";
import { InviteTileViewModel } from "./InviteTileViewModel.js";
import { RoomBeingCreatedTileViewModel } from "./RoomBeingCreatedTileViewModel.js";
import { RoomFilter } from "./RoomFilter.js";
import { ApplyMap } from "../../../observable";
import { addPanelIfNeeded } from "../../navigation";

export class ContactViewModel extends ViewModel {
  constructor(options) {
    super(options);
    const { session } = options;
    // 过滤房间
    const roomData = session.rooms.filterValues((i) => {
      const summaryData = i._summary.data;
      const tags = summaryData.tags;
      if (tags?.fake_leave) {
        return false;
      }
      if (
        summaryData.needsHeroes &&
        !summaryData.inviteCount &&
        summaryData.joinCount <= 1
      ) {
        return false;
      }
      if (!i.name) return false;

      if (tags && tags["m.server_notice"]) {
        return false;
      }
      // if (i.joinedMemberCount <= 1) return false;
      return true;
    });

    this._tileViewModelsMap = this._mapTileViewModels(
      // session.roomsBeingCreated,
      session.invites,
      roomData
    );
    this._tileViewModelsFilterMap = new ApplyMap(this._tileViewModelsMap);
    this._tileViewModels = this._tileViewModelsFilterMap.sortValues((a, b) =>
      a.compare(b)
    );
    this._currentTileVM = null;
    this._setupNavigation();
    this._closeUrl = this.urlRouter.urlForSegment("session");
    this._settingsUrl = this.urlRouter.urlForSegment("settings");
    this._session = session;
    this.platform.global.navActive = 1;

    // console.log("this.-----------------", this.platform);
  }

  _mapTileViewModels(invites, rooms) {
    // join is not commutative, invites will take precedence over rooms
    const allTiles = invites.join(rooms).mapValues((item, emitChange) => {
      let vm;
      if (item.isBeingCreated) {
        // vm = new RoomBeingCreatedTileViewModel(
        //   this.childOptions({ roomBeingCreated: item, emitChange })
        // );
      } else if (
        item.isInvite &&
        item?.inviter?.userId !== import.meta.env.VITE_SERVER_NOTICES_ID
      ) {
        vm = new InviteTileViewModel(
          this.childOptions({ invite: item, emitChange })
        );
      } else {
        vm = new RoomTileViewModel(
          this.childOptions({ room: item, emitChange, listType: "contact" })
        );
      }
      const isOpen = this.navigation.path.get("room")?.value === item.id;
      if (isOpen) {
        vm.open();
        this._updateCurrentVM(vm);
      }
      return vm;
    });
    return allTiles;
  }

  _updateCurrentVM(vm) {
    // need to also update the current vm here as
    // we can't call `_open` from the ctor as the map
    // is only populated when the view subscribes.
    this._currentTileVM?.close();
    this._currentTileVM = vm;
  }

  get closeUrl() {
    return this._closeUrl;
  }

  get settingsUrl() {
    return this._settingsUrl;
  }

  gotoMine() {
    this.navigation.push("self");
    // window.location.href = `#/mine/${this._session._sessionInfo.id}/self`;
  }

  gotoMessage() {
    this.platform.global.navActive = 0;
    window.location.href = this.urlRouter.urlUntilSegment("session");
  }

  showCreateRoomView() {
    this.navigation.push("create-room");
  }

  showJoinRoomView() {
    this.navigation.push("join-room");
  }

  showAddFriendView() {
    this.navigation.push("add-friend");
  }

  _setupNavigation() {
    const roomObservable = this.navigation.observe("room");
    this.track(roomObservable.subscribe((roomId) => this._open(roomId)));

    const gridObservable = this.navigation.observe("rooms");
    this.gridEnabled = !!gridObservable.get();
    this.track(
      gridObservable.subscribe((roomIds) => {
        const changed = this.gridEnabled ^ !!roomIds;
        this.gridEnabled = !!roomIds;
        if (changed) {
          this.emitChange("gridEnabled");
        }
      })
    );
  }

  _open(roomId) {
    this._currentTileVM?.close();
    this._currentTileVM = null;
    if (roomId) {
      this._currentTileVM = this._tileViewModelsMap.get(roomId);
      this._currentTileVM?.open();
    }
  }

  toggleGrid() {
    const room = this.navigation.path.get("room");
    let path = this.navigation.path.until("session");
    if (this.gridEnabled) {
      if (room) {
        path = path.with(room);
        path = addPanelIfNeeded(this.navigation, path);
      }
    } else {
      if (room) {
        path = path.with(this.navigation.segment("rooms", [room.value]));
        path = path.with(room);
        path = addPanelIfNeeded(this.navigation, path);
      } else {
        path = path.with(this.navigation.segment("rooms", []));
        path = path.with(this.navigation.segment("empty-grid-tile", 0));
      }
    }
    this.navigation.applyPath(path);
  }

  get tileViewModels() {
    return this._tileViewModels;
  }

  clearFilter() {
    this._tileViewModelsFilterMap.setApply(null);
    this._tileViewModelsFilterMap.applyOnce(
      (roomId, vm) => (vm.hidden = false)
    );
  }

  setFilter(query) {
    query = query.trim();
    if (query.length === 0) {
      this.clearFilter();
      return false;
    } else {
      const startFiltering = !this._tileViewModelsFilterMap.hasApply();
      const filter = new RoomFilter(query);
      this._tileViewModelsFilterMap.setApply((roomId, vm) => {
        vm.hidden = !filter.matches(vm);
      });
      return startFiltering;
    }
  }

  gotoSearch() {
    this.navigation.push("search");
  }


}
