
// import {ViewModel} from "../../ViewModel";
import {avatarInitials, getIdentifierColorNumber, getAvatarHttpUrl} from "../../avatar";
import { HomeServerApi } from "../../../matrix/net/HomeServerApi";
import { BaseTileViewModel } from "../leftpanel/BaseTileViewModel.js";

export class MemberTileViewModel extends BaseTileViewModel {
  constructor(options) {
    super(options);
    this._member = this._options.member;
    this._mediaRepository = options.mediaRepository;
    this._powerLevels = options.powerLevels;
    this._sessionInfo = options.sessionInfo;
    this._previousName = null;
    this._nameChanged = true;
    this._mute = this._powerLevels._plEvent.content.users[this.userId] !== -1;
    this._hide = false;
    this._isManager =
      this._powerLevels._plEvent.content.users[this.userId] === 100;
  }

  get name() {
    const userRemark = this.platform.store.getFriendNickName(this.userId);
    return userRemark?userRemark:`${this._member.name}${this._disambiguationPart}`;
  }

  get _disambiguationPart() {
    return this._disambiguate ? ` (${this.userId})` : "";
  }

  get userId() {
    return this._member.userId;
  }

  get previousName() {
    return this._previousName;
  }

  get nameChanged() {
    return this._nameChanged;
  }


  get detailsUrl() {
    const roomId = this.navigation.path.get("room").value;
    return `${this.urlRouter.openRoomActionUrl(
      roomId
    )}/member/${encodeURIComponent(this._member.userId)}`;
  }

  get roomId() {
    return this._member.roomId;
  }

  get membership() {
    return this._member.membership;
  }

  _updatePreviousName(newName) {
    const currentName = this._member.name;
    if (currentName !== newName) {
      this._previousName = currentName;
      this._nameChanged = true;
    } else {
      this._nameChanged = false;
    }
  }

  setDisambiguation(status) {
    this._disambiguate = status;
    this.emitChange();
  }

  updateFrom(newMember) {
    this._updatePreviousName(newMember.name);
    this._member = newMember;
  }

  get avatarLetter() {
    return avatarInitials(this.name);
  }

  get avatarColorNumber() {
    return getIdentifierColorNumber(this.userId);
  }

  avatarUrl(size) {
    return getAvatarHttpUrl(
      this._member.avatarUrl,
      size,
      this.platform,
      this._mediaRepository
    );
  }

  get avatarTitle() {
    return this.name;
  }

  get isAdmin() {
    // return this.userId === this._powerLevels._ownUserId;
    // return this.userId === this._powerLevels._plEvent.sender;
    return this._isManager;
  }

  get isManager() {
    return this._isManager;
  }

  get accessToken() {
    return this._sessionInfo.accessToken;
  }

  get currentUserRole() {
    if (this._sessionInfo.userId === this._powerLevels._plEvent.sender) {
      return "admin";
    }
    const CUserPower =
      this._powerLevels._plEvent.content.users[this._sessionInfo.userId];
    if (CUserPower === 100) {
      return "manage";
    } else {
      return "default";
    }
  }

  get hide() {
    return this._hide;
  }

  get mute() {
    return this._mute;
    // const currentUserRole = this._powerLevels._plEvent.content.users[this.userId] || 0
    // if(currentUserRole !== 2){
    //   return true
    // }else{
    //   return false
    // }
  }

  // //  === 2
  // get unmute() {
  //   return !this.mute;
  // }

  async handleUnMange(type) {
    console.log("handleUnMange", type);

    const request = this.platform.request;
    const homeserver = this.platform.config["defaultHomeServer"];
    const hsApi = new HomeServerApi({
      homeserver,
      request,
      accessToken: this.accessToken,
    });
    const data = await hsApi
      .roomPowerLevels(this.roomId, {
        ...this._powerLevels._plEvent.content,
        users: {
          ...this._powerLevels._plEvent.content.users,
          [this.userId]: type === "cancel" ? 0 : 100,
        },
      })
      .response();

    if (type === "cancel") {
      this._isManager = false;
    } else {
      this._isManager = true;
    }
    this.emitChange("isManager");
    this.emitChange("currentUserRole");
  }


  /**
   * 踢出
   */
  async handleKick() {
     window.document.dispatchEvent(
       new CustomEvent("KICK_USER_DIALOG", {
         detail: {
           roomId: this.roomId,
           userId: this.userId,
         },
       })
     );
  }


  async handleMute(userId) {
    console.log("禁言", this.userId);

    const request = this.platform.request;
    const homeserver = this.platform.config["defaultHomeServer"];
    const hsApi = new HomeServerApi({
      homeserver,
      request,
      accessToken: this.accessToken,
    });
    const data = await hsApi
      .roomPowerLevels(this.roomId, {
        ...this._powerLevels._plEvent.content,
        users: {
          ...this._powerLevels._plEvent.content.users,
          [this.userId]: -1,
        },
      })
      .response();
    if (data?.event_id) {
      // this._powerLevels._plEvent.content.users[this.userId] = 2
      this._mute = false;
      this.emitChange("mute");
    }
  }

  async handleUnMute(userId) {
    console.log("取消禁言", this.userId);

    const request = this.platform.request;
    const homeserver = this.platform.config["defaultHomeServer"];
    const hsApi = new HomeServerApi({
      homeserver,
      request,
      accessToken: this.accessToken,
    });
    const data = await hsApi
      .roomPowerLevels(this.roomId, {
        ...this._powerLevels._plEvent.content,
        users: {
          ...this._powerLevels._plEvent.content.users,
          [this.userId]: 0,
        },
      })
      .response();

    if (data?.event_id) {
      // this._powerLevels._plEvent.content.users[this.userId] = 3;
      this._mute = true;
      this.emitChange("mute");
    }
  }
}
