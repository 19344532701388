import request from "./request";

/**
 * 检查用户登录白名单
 * @param {string} userId
 * @returns
 */
export function whitelistCheck(userId) {
  return request({
    url: `/whitelist/check?user_id=${userId}`,
    method: "GET",
  });
}

/**
 * 限制通ip注册次数
 */
export function userIpRegisterCheck() {
  return request({
    url: `/limitregip/register`,
    method: "post",
  });
}


/**
 * 删除用户再群内的消息
 */
export function redactUserMessage(data) {
  return request({
    url: `/redact_user_messages`,
    method: "POST",
    data
  });
}

/**
 * 修改密码
 */
export function updatePassword(data) {
  return request({
    url: `/changepass`,
    method: "POST",
    data,
  });
}
