




import { ViewModel } from "../../ViewModel";
import { Client } from "../../../matrix/Client.js";
import { avatarInitials, getIdentifierColorNumber } from "../../avatar";
import { getAvatarHttpUrl } from "../../avatar";
import { HomeServerApi } from "../../../matrix/net/HomeServerApi";

// 群二维码
export class AnnouncementModel extends ViewModel {
  constructor(options) {
    super(options);
    this._room = options.room;
    this._onRoomChange = this._onRoomChange.bind(this);
    this._room.on("change", this._onRoomChange);
    this._session = options.session;
    this._powerLevels = options.powerLevelsObservable.get();
    // console.log("this._session", this._session);

    this._client = new Client(this.platform);
    this._loading = false;
    this._closeUrl = this.urlRouter.urlForSegment("details");
    this._error = null;
    this.sessionInfo = {};
    this.load();
  }

  async load() {
    if (this._loading) {
      return;
    }
    try {
      this._loading = true;
      this.emitChange("loading");

      const sessionId = this.navigation.path.get("session")?.value;
      this._sessionInfo = await this.platform.sessionInfoStorage.get(sessionId);

      console.log("this._room---", this._room);

      // const request = this.platform.request;
      // const homeserver = this.platform.config["defaultHomeServer"];
      // const hsApi = new HomeServerApi({
      //   homeserver,
      //   request,
      //   accessToken: this._sessionInfo.accessToken,
      // });
      // const data = await hsApi.messages(this.roomId, { dir: "b" }).response();
      // const topic = data.chunk.find((i) => i.type === "m.room.topic");
      // if (topic) {
      //   this._topic = topic?.content?.topic || "";
      // }

      const topicMap = await this._room.observeStateType("m.room.topic");
      this._topic = topicMap._values.get("")?.content?.topic || "";

      //  const topicEvent = this._room.getStateEvents("m.room.topic", "");
      //  this._topic =
      //    topicEvent && topicEvent.getContent()
      //      ? topicEvent.getContent()["topic"]
      //      : "";
    } finally {
      this._loading = false;
      this.emitChange("loading");
    }
  }

  get type() {
    return "announcement";
  }

  get isAdmin() {
    return this._powerLevels._myLevel >= 100;
  }

  get hideCloseButton() {
    return true;
  }

  get roomId() {
    return this._room.id;
  }

  get closeUrl() {
    return this._closeUrl;
  }

  get displayname() {
    return this._room.name;
  }

  get loading() {
    return this._loading;
  }

  get QRCode() {
    return this._QRCode;
  }

  get userId() {
    return this._session?.userId;
  }

  get topic() {
    return this._topic;
  }

  get avatarColorNumber() {
    return getIdentifierColorNumber(this.userId);
  }

  get avatarInitials() {
    return avatarInitials(this.userId);
  }

  avatarUrl(size) {
    return getAvatarHttpUrl(
      this._room.avatarUrl,
      size,
      this.platform,
      this._room.mediaRepository
    );
  }

  _onRoomChange() {
    this.emitChange();
  }

  dispose() {
    super.dispose();
    this._room.off("change", this._onRoomChange);
  }

  openPanel(segment) {
    let path = this.navigation.path.until("room");
    path = path.with(this.navigation.segment("right-panel", true));
    path = path.with(this.navigation.segment(segment, true));
    this.navigation.applyPath(path);
  }

  async handleAnnouncement(value) {
    console.log("handleAnnouncement--", value);
    if (!value) {
      this.setError(this.i18n`Please enter a name for the room`);
      return;
    }

    const request = this.platform.request;
    const homeserver = this.platform.config["defaultHomeServer"];
    const hsApi = new HomeServerApi({
      homeserver,
      request,
      accessToken: this._sessionInfo.accessToken,
    });
    await hsApi
      .updateRoomTopic(this._room.id, {
        topic: value,
        "org.matrix.msc3765.topic": [
          {
            body: value,
            mimetype: "text/plain",
          },
        ],
      })
      .response();

    this.emitChange("displayname");
    this.openPanel("details");
  }
}
