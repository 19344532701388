/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import {Client, LoadStatus, LoginFailure} from "../../matrix/Client.js";
import type {PasswordLoginMethod, ILoginMethod} from "../../matrix/login";
import {Options as BaseOptions, ViewModel} from "../ViewModel";
import type {LoginOptions} from "../login/LoginViewModel";
// import { userIpRegisterCheck } from "../../api/index";

type Options = {
  // loginOptions: LoginOptions | undefined;
  // attemptLogin: (loginMethod: PasswordLoginMethod) => Promise<null>;
} & BaseOptions
export class RegisterViewModel extends ViewModel {
  private _loginOptions?: LoginOptions;
  // private _attemptLogin: (loginMethod: PasswordLoginMethod) => Promise<null>;
  private _isBusy = false;
  private _errorMessage = "";
  private _client: Client;
  // private _abortQueryOperation?: () => void;

  constructor(options: Options) {
    super(options);
    this._client = new Client(this.platform);

    // const {loginOptions, attemptLogin} = options;
    // this._loginOptions = loginOptions;
    // this._attemptLogin = attemptLogin;
    this.init()
  }

  get isBusy(): boolean { return this._isBusy; }
  get errorMessage(): string { return this._errorMessage; }
  // 注册loading展示
//  get isFetchingLoginOptions(): boolean {
//     return !!this._abortQueryOperation;
// }

  async init(){
    const hs = this.platform.config["defaultHomeServer"];
    const queryOperation = this._client.queryLogin(hs);
    this._loginOptions = await queryOperation.result;
    // this._abortQueryOperation = this.track(() => queryOperation.abort());
    // this.emitChange("isFetchingLoginOptions"); // z
    // this.emitChange("resolvedHomeserver");
  }

  setBusy(status: boolean): void {
    this._isBusy = status;
    this.emitChange("isBusy");
  }

  _showError(message: string): void {
    this._errorMessage = message;
    this.emitChange("errorMessage");
  }

  goBack(): void {
    this.navigation.push("login");
  }

  async attemptLogin(loginMethod: ILoginMethod): Promise<null> {
    void this._client.startWithLogin(loginMethod, {inspectAccountSetup: false});
    const loadStatus = this._client.loadStatus;
    const handle = loadStatus.waitFor((status: LoadStatus) => status !== LoadStatus.Login);
    await handle.promise;
    const status = loadStatus.get();
    if (status === LoadStatus.LoginFailed) {
        return this._client.loginFailure;
    }
    return null;
  }

  async register(username: string, password: string, rPassword: string): Promise<void>{
    // const {msg} = await userIpRegisterCheck();
    // if (msg === 'no') return this._showError( this.i18n`The maximum number of registrations for this IP address has been reached`);
    if (password !== rPassword) {
      return this._showError(this.i18n`Passwords don't match`)
    }
    this.setBusy(true)
    this._errorMessage = "";
    this.emitChange("errorMessage");
    const hs = this.platform.config["defaultHomeServer"];
    try {
      const reg = await this._client.startRegistration(hs, username, password, 'Hydrogen')
      const stage = await reg.start()
      const res = await reg.submitStage(stage)
      if (!res) {
        alert(this.i18n`Registration Successful`)
        const status = await this.attemptLogin(this._loginOptions!.password!(username, password));
        let error = "";
        switch (status) {
            case LoginFailure.Credentials:
                error = this.i18n`Incorrect username and/or password.`;
                break;
            case LoginFailure.Connection:
                error = this.i18n`Can't connect to ${this._loginOptions!.homeserver}.`;
                break;
            case LoginFailure.Unknown:
                error = this.i18n`Something went wrong while checking your login and password.`;
                break;
        }
        if (error) {
            this._showError(error);
        } else {
          setTimeout(async() => {
            const sessionInfos = await this.platform.sessionInfoStorage.getAll();
            if (sessionInfos.length === 0) {
              this.navigation.push("login");
            } else if (sessionInfos.length === 1) {
              this.navigation.push("session", sessionInfos[0].id);
            } else {
              this.navigation.push("session");
            }
          },500)
        }
        // todo 直接登录
        // return this.navigation.push('login')
      }
    } catch (err) {
      let error = "";
      let status;
      if (err.name === "HomeServerError") {
        if (err.errcode === "M_FORBIDDEN") {
          status = LoginFailure.Credentials;
        } else {
          status = LoginFailure.Unknown;
        }
      } else if (err.name === "ConnectionError") {
        status = LoginFailure.Connection;
      }
      switch (status) {
        case LoginFailure.Credentials:
          error = this.i18n`Incorrect username and/or password.`;
          break;
        case LoginFailure.Connection:
          error = this.i18n`Can't connect to ${hs}.`;
          break;
        case LoginFailure.Unknown:
          error = this.i18n`Something went wrong while checking your login and password.`;
          break;
      }
      if (err.errcode === "M_USER_IN_USE") {
        error = this.i18n`Someone already has that username, please try another.`;
      } else if (err.errcode === "M_THREEPID_IN_USE") {
        error = this.i18n`That e-mail address is already in use.`;
      }
      this._showError(error)
    }
    this.setBusy(false)
  }
}
