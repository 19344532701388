/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { ViewModel } from "../../ViewModel";
import { RoomType } from "../../../matrix/room/common";
import {
  avatarInitials,
  getIdentifierColorNumber,
  getAvatarHttpUrl,
} from "../../avatar";

export class MemberDetailsAddFriendViewModel extends ViewModel {
  constructor(options) {
    super(options);
    const { session } = options;

    this._session = session;
    this._loading = false;
    this._displayname = "";
    this._friendId = "";
    this.load();
  }

  async load() {
    if (this._loading) {
      return;
    }
    try {
      this._loading = true;

      const friendId = sessionStorage.getItem("FRIEND_ID");
      // console.log("friendId", friendId);
      if (!friendId) {
        let path = this.navigation.path.until("session");
        this.navigation.applyPath(path);
        return;
      }

    
      // 检查是否就是自己
      if (friendId === this._session._user._userId) {
          let path = this.navigation.path.until("session");
          window.location.href = `#/${path.segments[0].type}/${path.segments[0].value}`;
          return;
      }


      // 检查是否已经是好友
      const room = this._session.findDirectMessageForUserId(friendId);
      if (room) {
        //  判断是否已经离开
        const tag = room._summary.data?.tags?.fake_leave
        if(!tag){
           let path = this.navigation.path.until("session");
           window.location.href = `#/${path.segments[0].type}/${
             path.segments[0].value
           }/room/${decodeURIComponent(room.id)}`;
           return;
        }
      }

      this._friendId = friendId;
      const profile = await this._session._hsApi.profile(friendId).response();
      console.log("profile", profile, room);

      this._displayname = profile.displayname;
      this._avatarUrl = profile.avatar_url;

      this.emitChange("name");
    } finally {
      this._loading = false;
      this.emitChange("loading");
      sessionStorage.removeItem("FRIEND_ID");
    }
  }

  get displayname() {
    return this._displayname;
  }

  get showUserId() {
    return this._friendId.slice(0, this._friendId.indexOf(":"));
  }

  get userId() {
    // return this._userId;

    return this._session._sessionInfo.id;
  }

  get avatarLetter() {
    return avatarInitials(this.name);
  }

  get avatarColorNumber() {
    return getIdentifierColorNumber(this.userId);
  }

  avatarUrl(size) {
    return getAvatarHttpUrl(
      this._avatarUrl,
      size,
      this.platform,
      this._session.mediaRepository
    );
  }

  get notify() {
    return this._notify;
  }

  get currentUserId() {
    return this._session?.userId;
  }

  back() {
    let path = this.navigation.path.until("session");
    this.navigation.applyPath(path);
    return;
  }

  async handleAddFriend() {
    const room = this._session.findDirectMessageForUserId(this._friendId);

    if (room) {
      this._session._hsApi
        .fake_leave_del(this.currentUserId, room.id, {})
        .response();

      this.navigation.push("room", room.id);
    } else {
      // 创建房间
      const roomBeingCreated = await this._session.createRoom({
        type: RoomType.DirectMessage,
        // preset: "trusted_private_chat",
        name: undefined,
        topic: undefined,
        isEncrypted: false,
        isFederationDisabled: true,
        alias: undefined,
        avatar: undefined,
        invites: [this._friendId],
      });
      this.navigation.push("room", roomBeingCreated.roomId);
    }
  }
}
