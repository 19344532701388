/*
Copyright 2020 Bruno Windels <bruno@windels.cloud>
Copyright 2020 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import { ViewModel } from "../../ViewModel";
import { RoomTileViewModel } from "./RoomTileViewModel.js";
import { InviteTileViewModel } from "./InviteTileViewModel.js";
import { RoomBeingCreatedTileViewModel } from "./RoomBeingCreatedTileViewModel.js";
import { RoomFilter } from "./RoomFilter.js";
import { ApplyMap } from "../../../observable";
import { addPanelIfNeeded } from "../../navigation";
import { RoomType } from "../../../matrix/room/common";
import { cloneDeep, set, get } from "lodash";

export class LeftPanelViewModel extends ViewModel {
  constructor(options) {
    super(options);
    const { session } = options;
    this._session = session;
    this._messageAllCountObj = {};

    // 过滤房间
    const roomData = this._session.rooms.filterValues((i) => {
      const summaryData = i._summary.data;
      const tags = summaryData.tags;
      if (tags?.fake_leave) {
        return false;
      }

      if (
        summaryData.needsHeroes &&
        !summaryData.inviteCount &&
        summaryData.joinCount <= 1
      ) {
        return false;
      }
      if (!i.name) return false;
      if (summaryData.notificationCount > 0) {
        set(
          this._messageAllCountObj,
          [summaryData.roomId],
          summaryData.notificationCount
        );
      }
      // if(i.joinedMemberCount<= 1)return false;
      return true;
    });
    this._tileViewModelsMap = this._mapTileViewModels(
      this._session.roomsBeingCreated,
      this._session.invites,
      roomData
    );
    this._tileViewModelsFilterMap = new ApplyMap(this._tileViewModelsMap);
    this._tileViewModels = this._tileViewModelsFilterMap.sortValues((a, b) =>
      a.compare(b)
    );

    // console.log("--_tileViewModels--", this._tileViewModels);
    this._currentTileVM = null;
    this._setupNavigation();
    this._closeUrl = this.urlRouter.urlForSegment("session");
    this._settingsUrl = this.urlRouter.urlForSegment("settings");
    this.platform.global.navActive = 0;
    this.platform.global.userId = this._session?.userId;
    this.platform.global.sessionId = this._session?._sessionInfo.id;
    this._override = null;
    this._disturbStatusData = {};
    setTimeout(() => this._defaultFriends(), 800);
    this._pushrules();
    this._handleInviter();
    this._scanCode();
    this._roomListChange();
    this._addEventListener();

    // check clear
    this._clear();
  }

  _mapTileViewModels(BeingCreated, invites, rooms) {
    // join is not commutative, invites will take precedence over rooms

    this.platform.store.setFriendNickName(cloneDeep(rooms));
    const allTiles = invites
      .join(rooms, BeingCreated)
      .mapValues((item, emitChange) => {
        // console.log("item----->", item)
        let vm;
        if (item.isBeingCreated) {
          vm = new RoomBeingCreatedTileViewModel(
            this.childOptions({ roomBeingCreated: item, emitChange })
          );
        } else if (item.isInvite) {
          vm = new InviteTileViewModel(
            this.childOptions({ invite: item, emitChange })
          );
          this._handleSync(item);
        } else {
          // const timeline = await item.openTimeline();
          // console.log("--------tile----", timeline);
          vm = new RoomTileViewModel(
            this.childOptions({
              room: item,
              emitChange,
              // timeline: timeline,
            })
          );
        }
        const isOpen = this.navigation.path.get("room")?.value === item.id;
        if (isOpen) {
          vm.open();
          this._updateCurrentVM(vm);
        }
        return vm;
      });
    return allTiles;
  }

  _updateCurrentVM(vm) {
    // need to also update the current vm here as
    // we can't call `_open` from the ctor as the map
    // is only populated when the view subscribes.
    this._currentTileVM?.close();
    this._currentTileVM = vm;
  }

  _roomListChange() {
    // window.document.addEventListener("ROOM_LIST_UPDATE", () => {
    //   this._tileViewModelsFilterMap.setApply(null);
    // });
  }

  // 处理服务号
  async _defaultFriends(callback = () => {}) {
    this.postParentMessage();


    const enable_add_one_friend =
      this.platform.remoteConfig?.enable_add_one_friend;
    const default_friends = (this.platform.remoteConfig?.default_friends || [])
      .filter((i) => i)
      .filter((i) => this._session?.userId !== i);
    console.log("default_friends", default_friends);

    // 检查是否已经有服务号房间了
    let friendNum = 0;
    for (let index = 0; index < default_friends.length; index++) {
      const userId = default_friends[index];
      const room = this._session.findDirectMessageForUserId(userId);
      if (room) {
        friendNum++;
      }
      if (enable_add_one_friend && friendNum) break;
    }
    console.log("default_friends", friendNum);

    // console.log("room---222--", enable_add_one_friend, friendNum);
    if (enable_add_one_friend && friendNum) return;
    if (default_friends.length === friendNum) return;

    if (enable_add_one_friend) {
      const fIndex =
        Math.floor(Math.random() * (default_friends.length - 0 + 1)) + 0;
      const oneFriend = default_friends[fIndex];
      if (!oneFriend) return;
      const roomBeingCreated = await this._session.createRoom({
        type: RoomType.DirectMessage,
        name: undefined,
        topic: undefined,
        isEncrypted: false,
        isFederationDisabled: true,
        alias: undefined,
        avatar: undefined,
        invites: [oneFriend],
      });
      this._open(roomBeingCreated.roomId);
      this._tileViewModelsFilterMap.setApply(null);
      callback();
      return;
    }

    for (let index = 0; index < default_friends.length; index++) {
      const userId = default_friends[index];
      const room = this._session.findDirectMessageForUserId(userId);
      if (room) {
        continue;
      }
      if (!userId) continue;
      const roomBeingCreated = await this._session.createRoom({
        type: RoomType.DirectMessage,
        name: undefined,
        topic: undefined,
        isEncrypted: false,
        isFederationDisabled: true,
        alias: undefined,
        avatar: undefined,
        invites: [userId],
      });
      this._open(roomBeingCreated.roomId);
    }
    this._tileViewModelsFilterMap.setApply(null);
    callback();
  }

  get closeUrl() {
    return this._closeUrl;
  }

  get settingsUrl() {
    return this._settingsUrl;
  }

  // 我的页
  gotoMine() {
    this.navigation.push("self");
  }

  gotoContact() {
    // this.urlRouter.urlForSegment("contact");
    this.navigation.push("contact");
  }

  showCreateRoomView() {
    this.navigation.push("create-room");
  }

  showJoinRoomView() {
    this.navigation.push("join-room");
  }

  showAddFriendView() {
    this.navigation.push("add-friend");
  }

  _setupNavigation() {
    const roomObservable = this.navigation.observe("room");
    this.track(roomObservable.subscribe((roomId) => this._open(roomId)));

    const gridObservable = this.navigation.observe("rooms");
    this.gridEnabled = !!gridObservable.get();
    this.track(
      gridObservable.subscribe((roomIds) => {
        const changed = this.gridEnabled ^ !!roomIds;
        this.gridEnabled = !!roomIds;
        if (changed) {
          this.emitChange("gridEnabled");
        }
      })
    );
  }

  _open(roomId) {
    this._currentTileVM?.close();
    this._currentTileVM = null;
    if (roomId) {
      this._currentTileVM = this._tileViewModelsMap.get(roomId);
      this._currentTileVM?.open();
    }
  }

  toggleGrid() {
    const room = this.navigation.path.get("room");
    let path = this.navigation.path.until("session");
    if (this.gridEnabled) {
      if (room) {
        path = path.with(room);
        path = addPanelIfNeeded(this.navigation, path);
      }
    } else {
      if (room) {
        path = path.with(this.navigation.segment("rooms", [room.value]));
        path = path.with(room);
        path = addPanelIfNeeded(this.navigation, path);
      } else {
        path = path.with(this.navigation.segment("rooms", []));
        path = path.with(this.navigation.segment("empty-grid-tile", 0));
      }
    }
    this.navigation.applyPath(path);
  }

  get tileViewModels() {
    return this._tileViewModels;
  }

  get allRoomNotificationCount() {
    return Object.values(this._messageAllCountObj).reduce((cur, i) => {
      return cur + i;
    }, 0);
  }

  clearFilter() {
    this._tileViewModelsFilterMap.setApply(null);
    this._tileViewModelsFilterMap.applyOnce(
      (roomId, vm) => (vm.hidden = false)
    );
  }

  setFilter(query) {
    query = query.trim();
    if (query.length === 0) {
      this.clearFilter();
      return false;
    } else {
      const startFiltering = !this._tileViewModelsFilterMap.hasApply();
      const filter = new RoomFilter(query);
      this._tileViewModelsFilterMap.setApply((roomId, vm) => {
        vm.hidden = !filter.matches(vm);
      });
      return startFiltering;
    }
  }

  gotoSearch() {
    this.navigation.push("search");
  }

  async getOverride() {
    const pushrulesData = await this._session._hsApi.pushrulesAll().response();
    const { override } = pushrulesData?.global || {};
    return override;
  }

  handleDisturbStatus(e) {
    const { roomId, status } = e.detail;
    this._disturbStatusData[roomId] = status;
  }

  async _pushrules() {
    this._override = null;
    if (!this._override) {
      this._override = await this.getOverride();
      window.document.addEventListener(
        "disturb_status_update",
        this.handleDisturbStatus.bind(this)
      );
    }
    this._tileViewModelsFilterMap.setApply(async (roomId, vm) => {
      if (this._disturbStatusData[roomId] !== undefined) {
        vm.disturbStatus = this._disturbStatusData[roomId];
      } else {
        const disturbStatus =
          this._override.findIndex(
            (i) => i.rule_id === roomId && i.actions.includes("dont_notify")
          ) !== -1;

        vm.disturbStatus = disturbStatus;
        this._disturbStatusData[roomId] = disturbStatus;
      }
    });
  }

  // 邀请处理
  async _handleInviter() {
    for (let item of this._session.invites) {
      const roomId = item[1]?.inviter?.roomId;
      const userId = item[1]?.inviter?.userId;
      if (userId === import.meta.env.VITE_SERVER_NOTICES_ID) {
        this._session._hsApi.join(roomId);
      }
    }
  }

  async _handleSync(item) {
    if (item?.inviter?.userId === import.meta.env.VITE_SERVER_NOTICES_ID) {
      this._session._hsApi.join(item.inviter.roomId);
    }
  }

  async _scanCode() {
    const friendId = sessionStorage.getItem("FRIEND_ID");
    if (!friendId) return;
    setTimeout(() => this.navigation.push("view-code"), 0);
  }

  handleRemoteConfigUpdate(e) {
    const handleCallback = () => {
      if (location.href.indexOf("/contact") !== -1) {
        location.reload();
      }
    };

    const props = e?.detail?.props || [];
    const type = e?.detail?.type || "";
    if (type !== "defaultFriendUpdate") return;
    this.platform.remoteConfig.default_friends = props.filter((i) => i);
    this._defaultFriends(handleCallback);
  }

  handleMessageNotification(e) {
    const { notificationCount = "", roomId = "" } = e?.detail;
    try {
      if (!roomId) return;
      set(this._messageAllCountObj, [roomId], notificationCount);

      if (notificationCount > 0 &&  get(this._disturbStatusData, [roomId], false) === false ) {
        const audioElement = document.querySelector("#messageAudio");
        // audioElement.muted = true;
        audioElement?.play();
      }

      this.postParentMessage();
    } catch (error) {
      console.error(error)
    }
  }

  _addEventListener() {

    document.addEventListener(
      "config_update",
      this.handleRemoteConfigUpdate.bind(this)
    );
    document.addEventListener(
      "MESSAGE_NOTIFICATION",
      this.handleMessageNotification.bind(this)
    );
  }

  dispose() {
    super.dispose();
    document.removeEventListener(
      "config_update",
      this.handleRemoteConfigUpdate
    );
    document.removeEventListener(
      "MESSAGE_NOTIFICATION",
      this.handleMessageNotification
    );
  }

  postParentMessage(){
    console.log("---postParentMessage");
    window.parent.postMessage( {
      from: "IMCHARTMESSAGE",
      data: this.allRoomNotificationCount,
    }, "*");
  }

  // 错误数据清理
  _clear() {
    const nullNameRoomList = [];
    for (const item of this._session.rooms) {
      const summaryData = item[1]._summary.data;
      if (
        !summaryData?.name &&
        !summaryData?.dmUserId &&
        summaryData.joinCount <= 1
      ) {
        nullNameRoomList.push(summaryData.roomId);
        // console.log("--item[1]", item[1]);
      }
    }

    if (nullNameRoomList.length > 0) {
      nullNameRoomList.forEach(async (i) => {
        await this._session._hsApi.leave(i).response();
      });
    }
  }
}
