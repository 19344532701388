import { ViewModel } from "../ViewModel";
import { Client } from "../../matrix/Client.js";
import { updatePassword } from "../../api/index.js";

export class UpdatePassword extends ViewModel {
  constructor(options) {
    super(options);
    const { session } = options;
    this._sessionId = options.id;
    this._session = session;
    this._uid = this._session._sessionInfo.id;

    this._client = new Client(this.platform);
    this._loading = false;
    // this._closeUrl = this.urlRouter.urlUntilSegment("self");
    this._closeUrl = this.urlRouter.urlForSegment("self");
    this._error = null;
    this._inputPassword = ["", ""];
    this.load();
  }

  async load() {
    if (this._loading) {
      return;
    }
    try {
      this._loading = true;
      this.emitChange("loading");

      this._sessionInfos = await this.platform.sessionInfoStorage.get(
        this._uid
      );

      console.log("this._sessionInfos", this._sessionInfos);
    } finally {
      this._loading = false;
      this.emitChange("loading");
    }
  }

  get loading() {
    return this._loading;
  }

  get closeUrl() {
    return this._closeUrl;
  }

  get userId() {
    return this._session?.userId;
  }

  get error() {
    return this._error;
  }

  // 密码输入
  passwordInput(e, type) {
    const value = e.target.value.trim();
    this._displayname = value;
    this._inputPassword[type] = value;
  }

  setError(value) {
    this._error = value;
    this.emitChange("error");
  }

  // 修改密码
  async updatePassword() {
    if (!this._inputPassword[0] || !this._inputPassword[1]) {
      this.setError(this.i18n`Passwords can't be empty`);
      return;
    }
    if (this._inputPassword[0] !== this._inputPassword[1]) {
      this.setError(this.i18n`New passwords don't match`);
      return;
    }
    if (
      this._inputPassword[0]?.length > 16 ||
      this._inputPassword[0]?.length < 6
    ) {
      this.setError(this.i18n`At least 6 digits of pure numbers`);
      return;
    }

    await updatePassword({
      password: this._inputPassword[0],
      user_id: this.userId,
    })
    const token = this.platform.store.loginToken
    if (token){
      location.href = `/#/thirdparty/${token}/hide-back`;  
    } else{
      localStorage.clear()
      this.navigation.push("login", true);
      // location.href = this.closeUrl;
    }
  }
}
