

import { TemplateView } from "./TemplateView";



export class FooterView extends TemplateView {
    static option(label, callback) {
        return new MenuOption(label, callback);
    }

    constructor(options) {
        super();
        this._options = options;
    }

    render(t) {
        return t.div({className: "footer", role: "footer"}, this._options.map(o => o.toDOM(t)));
    }
}

class MenuOption {
    constructor(label, callback) {
        this.label = label;
        this.callback = callback;
        this.icon = null;
        this.destructive = false;
    }

    setIcon(className) {
        this.icon = className;
        return this;
    }

    setDestructive() {
        this.destructive = true;
        return this;
    }

    toDOM(t) {
        const className = {
            destructive: this.destructive,
        };
        if (this.icon) {
            className.img = true;
            className[this.icon] = true;
        }
        return t.div(
          {
            className: {
              item: true,
              active: this.destructive,
            },
            onClick: this.callback,
          },
          [
            t.span({ className: "icon" }, [t.div({ className })]),
            t.span({ className: "item" }, this.label),
          ]
        );
    }
}

