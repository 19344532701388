import { TemplateView } from "../general/TemplateView";
import { LoadingView } from "../general/LoadingView";
export class QRCodeView extends TemplateView {
  render(t, vm) {
    return t.main({ className: "qrcode-layer" }, [
      t.map(
        (vm) => vm.loading,
        (l, t) => {
          if (l) {
            return t.view(new LoadingView(vm.i18n`Loading`));
          }

          
          // view
          return t.div({ className: "content" }, [
            t.div({ className: "header" }, [
              t.a({
                className: "button-utility close-middle",
                href: vm.closeUrl,
              }),
            ]),

            t.div(
              {
                className: "avatar",
              },
              t.div(
                { className: `avatar usercolor${vm.avatarColorNumber}` },
                (vm) => vm.avatarInitials
              )
            ),
            t.div(
              {
                className: "nickname",
              },
              vm.displayname
            ),
            t.div(
              {
                className: "code",
              },
              [t.img({ className: "img", src: vm.QRCode })]
            ),
          ]);
          
        }
      ),
    ]);
  }
}
