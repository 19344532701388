import { ViewModel } from "../../ViewModel";
// import { Client } from "../../../matrix/Client.js";
// import { HomeServerApi } from "../../../matrix/net/HomeServerApi";

import { MemberTileViewModel } from "./MemberTileViewModel.js";
import { createMemberComparator } from "../rightpanel/members/comparator.js";
import { Disambiguator } from "../rightpanel/members/disambiguator.js";
import { MembersManageSelectFilter } from "./MembersManageSelectFilter.js";
import { ApplyMap } from "../../../observable";
import { EventType } from "../../../utils/event";
import { redactUserMessage } from "../../../api";

// 成员
export class MembersModel extends ViewModel {
  constructor(options) {
    super(options);
    this._session = options.session;
    this._room = options.room;

    this._showKickDialog = false;
    this._kickConfig = {};
    this._category = {
      admin: false,
      manager: false,
      other: false,
    };
    this._loading = false;
    this.load();

    const list = options.members;
    console.log("-list---", list);
    this._members = list;
    const powerLevelsObservable = options.powerLevelsObservable;
    this.track(
      powerLevelsObservable.subscribe(() => {
        /*resort based on new power levels here*/
      })
    );
    const powerLevels = powerLevelsObservable.get();
    // console.log(
    //   "powerLevelsObservable---room",
    //   options.room,
    //   "this._members",
    //   this._members
    // );
    this._powerLevels = powerLevels;

    this._memberTileViewMap = this._mapTileViewModels(
      list.members.filterValues((member) => member.membership !== "leave")
    );
    this._tileViewModelsFilterMap = new ApplyMap(this._memberTileViewMap);
    this._memberTileViewModels = this._tileViewModelsFilterMap.sortValues(
      createMemberComparator(powerLevels)
    );

    // this.nameDisambiguator = new Disambiguator();
    this.mediaRepository = options.mediaRepository;
  }

  async load() {
    if (this._loading) {
      return;
    }
    try {
      this._loading = true;
      this.emitChange("loading");

      const sessionId = this.navigation.path.get("session")?.value;
      this._sessionInfo = await this.platform.sessionInfoStorage.get(sessionId);
      // console.log("this._sessionInfo ", this._sessionInfo);

      // this._sessionInfo = await this.platform.sessionInfoStorage.get(
      //   this._session._sessionInfo.id
      // );

      // const request = this.platform.request;
      // const homeserver = this.platform.config["defaultHomeServer"];
      // const hsApi = new HomeServerApi({ homeserver, request });
      // this._profile = await hsApi.profile(this.userId).response();

      document.addEventListener(
        "KICK_USER_DIALOG",
        this.handleShowKickDialog.bind(this)
      );
    } finally {
      this._loading = false;
      this.emitChange("loading");
    }
  }

  handleShowKickDialog(e) {
    console.log("e--", e.detail);
    this._kickConfig = e.detail;
    this._showKickDialog = true;
    this.emitChange("showKickDialog");
  }

  // kick
  handleKickCheckbox(e){
     const { checked } = e.target;
    this._kickConfig.delChatRecord = checked;
  }

  hideKickDialog() {
    this._kickConfig = {};
    this._showKickDialog = false;
    this.emitChange("showKickDialog");
  }

  get type() {
    return "member-management";
  }

  get shouldShowBackButton() {
    return false;
  }

  get hideCloseButton() {
    return true;
  }

  get loading() {
    return this._loading;
  }

  get userId() {
    return this._session?.userId;
  }

  get memberTileViewModels() {
    return this._memberTileViewModels;
  }

  get showKickDialog() {
    return this._showKickDialog;
  }

  _onRoomChange() {
    this.emitChange();
  }

  dispose() {
    super.dispose();
    // this._room.off("change", this._onRoomChange);

    document.removeEventListener("KICK_USER_DIALOG", this.handleShowKickDialog);
  }

  clearFilter() {
    this._tileViewModelsFilterMap.setApply(null);
    this._tileViewModelsFilterMap.applyOnce(
      (roomId, vm) => (vm.hidden = false)
    );
  }

  setFilter(query) {
    query = query.trim();
    if (query.length === 0) {
      this.clearFilter();
      return false;
    } else {
      const startFiltering = !this._tileViewModelsFilterMap.hasApply();
      console.log("setFilter---", query, this._tileViewModelsFilterMap);
      const filter = new MembersManageSelectFilter(query);
      this._tileViewModelsFilterMap.setApply((roomId, vm) => {
        // console.log("-----", filter.matches(vm))
        vm.hidden = !filter.matches(vm);
      });
      return startFiltering;
    }
  }

  // 执行踢出用户
  async handleKickAction() {

     this._showKickDialog = false;
     this.emitChange("showKickDialog");

    const { userId, roomId, delChatRecord = false } = this._kickConfig;
    if (!userId) return;
    await this._session._hsApi
      .roomKick(roomId, {
        user_id: userId,
        reason: "h5-action-room-remove",
      })
      .response();


    // 如果勾选删除聊天记录
    if (!delChatRecord) return;
    redactUserMessage({
      room_id: roomId,
      user_id: userId,
    });

    // const msgTypes = [
    //   EventType.RoomMessage,
    //   EventType.RoomEncryption,
    //   EventType.RoomMessageEncrypted,
    //   EventType.Sticker,
    // ];
    // const allThreads = this._room._timeline._remoteEntries.array.filter(
    //   (t) => msgTypes.includes(t.eventType) && !t.isRedacted
    // );
    // for (let i = 0; i < allThreads.length; i++) {
    //   const t = allThreads[i];
    //   const sender = t?.sender;
    //   // console.log("sender, userId", sender, userId, t);
    //   try {
    //     const txnId = "m" + new Date().getTime() + "." + i;
    //     if(sender === userId){
    //       await this._session._hsApi.redact(roomId, t.id, txnId, {});
    //     }
    //   } catch (e) {
    //     console.error(e);
    //   }
    // }


  }

  openPanel(segment) {
    let path = this.navigation.path.until("room");
    path = path.with(this.navigation.segment("right-panel", true));
    path = path.with(this.navigation.segment(segment, true));
    this.navigation.applyPath(path);
  }

  _mapTileViewModels(members) {
    const mapper = (member, emitChange) => {
      const mediaRepository = this.mediaRepository;
      let vm = new MemberTileViewModel(
        this.childOptions({
          member,
          emitChange,
          mediaRepository,
          sessionInfo: this._sessionInfo,
          powerLevels: this._powerLevels,
        })
      );
      return vm;
    };
    const updater = (params, vm, newMember) => {
      vm.updateFrom(newMember);
      // this.nameDisambiguator.disambiguate(vm);
    };
    return members.mapValues(mapper, updater);
  }
}
