/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import {Options as BaseOptions, ViewModel} from "../ViewModel";
import {LoginFailure} from "../../matrix/Client.js";
import type {TokenLoginMethod} from "../../matrix/login";
import { Client } from "../../matrix/Client.js";

type Options = {
  client: Client;
  attemptLogin: (loginMethod: TokenLoginMethod) => Promise<null>;
  loginToken?: string;
  handleError: (msg:string)=> void
} & BaseOptions;

export class ThirdPartyLoginViewModel extends ViewModel {
    private _loginToken: string;
    private _client: Client;
    private _attemptLogin: (loginMethod: TokenLoginMethod) => Promise<null>;
    private _handleError: (msg:string)=> void;
    private _errorMessage = "";

    constructor(options: Options) {
        super(options);
        const {
            // loginToken,
            client,
            attemptLogin,
            handleError,
        } = options;
        console.log("options----", options);
        // this._loginToken = loginToken;
        this._client = client;
        this._attemptLogin = attemptLogin;
        this._handleError = handleError;
        this._errorMessage = "";
        this._setToken();
        void this.performThirdPartyLoginCompletion();
    }

    _setToken(){
      const token = this.navigation.path.get("thirdparty")?.value;
      console.log("value---", decodeURIComponent(token));
      this._loginToken = token;
      this.platform.store.setLoginToken(token)
    }

    _getQuery = (url) => {
        const str = url.substr(url.indexOf('?') + 1)
        const arr = str.split('&')
        const result = {}
        for (let i = 0; i < arr.length; i++) {
            const item = arr[i].split('=')
            result[item[0]] = item[1]
        }
        return result
    }


    get errorMessage(): string { return this._errorMessage; }

    _showError(message: string): void {
        this._errorMessage = message;
        this.emitChange("errorMessage");
    }

    async performThirdPartyLoginCompletion(): Promise<void> {
        if (!this._loginToken) {
            return;
        }
        const homeserver = this.platform.config["defaultHomeServer"];// await this.platform.settingsStorage.getString("sso_ongoing_login_homeserver");
        let loginOptions: { token?: (loginToken: string) => TokenLoginMethod; };
        try {
            loginOptions = await this._client.queryLogin(homeserver).result;
        }
        catch (err) {
            this._showError(err.message);
            return;
        }

            // console.log("loginOptions----------", loginOptions);

        if (!loginOptions.token) {
            this.navigation.push("session");
            return;
        }
        const status = await this._attemptLogin(loginOptions.token(this._loginToken));
        // console.log("status-------------", status, LoginFailure.Credentials);
        let error = "";
        switch (status) {
            case LoginFailure.Credentials:
                error = this.i18n`Your login token is invalid.`;
                break;
            case LoginFailure.Connection:
                error = this.i18n`Can't connect to ${homeserver}.`;
                break;
            case LoginFailure.Unknown:
                error = this.i18n`Something went wrong while checking your login token.`;
                break;
        }
        if (error) {
          this._handleError(error);
            this._showError(error);
        }
    }
}
