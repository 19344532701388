import {TemplateView} from "../general/TemplateView";
import {spinner} from "../common";

export class RegisterView extends TemplateView {
  render(t, vm) {
    const disabled = vm => !!vm.isBusy;
    const username = t.input({
      id: 'username', type: 'text', placeholder: vm.i18n`Username`, disabled
    })
    const password = t.input({
      id: 'password', type: 'password', placeholder: vm.i18n`Password`, disabled
    })
    const confirm = t.input({
      id: 'confirm', type: 'password', placeholder: vm.i18n`Confirm password`, disabled
    })


    return t.div({className: "PreSessionScreen"}, [
      t.button({
        className: "button-utility LoginView_back",
        onClick: () => vm.goBack(),
      }),
      t.div({className: "logo"}),
      t.h1([vm.i18n`Register`]),
      t.if(vm => vm.isFetchingLoginOptions, t => t.div({className: "LoginView_query-spinner"}, [spinner(t), t.p("")])),
      t.div({className: "PasswordLoginView form"}, [
        t.form({
          onSubmit: evnt => {
            evnt.preventDefault();
            vm.register(username.value, password.value);
          }
        }, [
          t.if(vm => vm.errorMessage, (t, vm) => t.p({className: "error"}, vm.errorMessage)),
          t.div({ className: "form-row text" }, [t.label({ for: "username" }, vm.i18n`Username`), username]),
          t.div({ className: "form-row text" }, [t.label({ for: "password" }, vm.i18n`Password`), password]),
          t.div({ className: "form-row text" }, [t.label({ for: "confirm" }, vm.i18n`Confirm password`), confirm]),
          t.div({ className: "button-row" }, [
            t.button({
              className: "button-action primary",
              type: "button",
              onClick: () => {
                vm.register(username.value, password.value, confirm.value)
              },
              disabled
            }, vm.i18n`Register`),
          ]),
        ])
      ]),
    ]);

  }
}
