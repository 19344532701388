import { TemplateView } from "../../general/TemplateView";
import { ListView } from "../../general/ListView";
import { MembersMangeSelectTileView } from "./MembersMangeSelectTileView.js";

class FilterField extends TemplateView {
  render(t, options) {
    const clear = () => {
      filterInput.value = "";
      filterInput.blur();
      clearButton.blur();
      options.clear();
    };
    const filterInput = t.input({
      type: "text",
      placeholder: options?.label,
      "aria-label": options?.label,
      autocomplete: options?.autocomplete,
      enterkeyhint: "search",
      name: options?.name,
      onInput: (event) => options.set(event.target.value),
      onKeydown: (event) => {
        if (event.key === "Escape" || event.key === "Esc") {
          clear();
        }
      },
      onFocus: () => filterInput.select(),
    });
    const clearButton = t.button({
      onClick: clear,
      title: options.i18n`Clear`,
      "aria-label": options.i18n`Clear`,
    });
    return t.div({ className: "FilterField" }, [filterInput, clearButton]);
  }
}

// 成员管理  +   -
export class MembersMangeSelectView extends TemplateView {
  updateDisplayname(e) {
    const value = e.target.value;
    this._displayname = value;
    console.log("e", value);
  }

  render(t, vm) {
    return t.main({ className: "member-manage-select-layer" }, [
      t.map(
        (vm) => vm.loading,
        (l, t) => {
          if (l) {
            return t.div({ className: "loading" }, "loading");
          }

          return t.div({ className: "content" }, [
            t.div({ className: "header" }, [
              t.div({
                className: "button-utility LoginView_back",
                onClick: () => vm.openPanel("member-management"),
              }),
              t.div(
                {
                  className: "title",
                },
                vm.i18n`Choose Users`
              ),
            ]),
            t.view(
              new FilterField({
                i18n: vm.i18n,
                label: vm.i18n`Filter`,
                name: "filter",
                autocomplete: true,
                set: (query) => {
                  // scroll up if we just started filtering
                  if (vm.setFilter(query)) {
                    // roomList.scrollTop = 0;
                  }
                },
                clear: () => vm.clearFilter(),
              })
            ),

            // list

            t.view(
              new ListView(
                {
                  className: "RoomList",
                  list: vm.tileViewModels,
                  i18n: vm.i18n,
                  category: "group-manage",
                },
                (tileVM) => new MembersMangeSelectTileView(tileVM)
              )
            ),

            // footer
            t.map(
              (vm) => vm.isInviteIng,
              (ing, t, vm) => {
                if (ing) {
                  return t.div(
                    {
                      className: "manage-footer",
                    },
                    [
                      t.div(
                        {
                          className: "invite-btn",
                          onClick: () => vm.handleInvite(),
                        },
                        [t.span(vm.i18n`Inviting...`)]
                      ),
                    ]
                  );
                }

                return t.div(
                  {
                    className: "manage-footer",
                  },
                  [
                    t.div(
                      {
                        className: "invite-btn",
                        onClick: () => vm.handleInvite(),
                      },
                      [
                        t.span(vm.i18n`Invite users`),
                        t.map(
                          (vm) => vm.selectedNum,
                          (c, t) => {
                            if (c) {
                              return t.span({ className: "count" }, `(${c})`);
                            }
                            return t.span({ className: "count" }, "");
                          }
                        ),
                      ]
                    ),
                  ]
                );
              }
            ),
          ]);
        }
      ),
    ]);
  }
}
