const BASE_LOG_PATH = import.meta.env.VITE_IM_LOG_API_URL;

class IMLog {
  // 监听错误
  addEventListenerError() {
    window.addEventListener(
      "error",
      (event) => {
        let content = `${event?.message}: ${event.error?.stack?.toString()}`;
        if(event?.isTrusted)return;
        if(event?.message === undefined){
          content = JSON.stringify(event)
        }
        this.requestJSError({
          error_content: content,
          current_route: location.href,
          page_guid: `${event.type}: ${event.message}`,
          error_time: new Date().getTime,
          user_system: navigator.userAgent,
          user_network: navigator.connection?.effectiveType,
          user_id: window.platform?.global?.userId || "",
        });
        // console.log("error---", event);
      },
      true
    );

    window.addEventListener("rejectionhandled", (event) => {
      // 错误的详细信息在reason字段
      // console.log(event.reason);
      this.requestJSError({
        error_content: event.reason,
        page_guid: "rejectionhandled",
        current_route: location.href,
        error_time: new Date().getTime,
        user_system: navigator.userAgent,
        user_network: navigator.connection?.effectiveType,
        user_id: window.platform?.global?.userId || "",
      });
    });
  }

  requestJSError(props) {
    if (!navigator.sendBeacon) return;
    const url = BASE_LOG_PATH + "/api/request/jserror";
    navigator.sendBeacon(url, JSON.stringify(props));
  }

  // 线上debug 记录
  debugLog(props) {
    if (!navigator.sendBeacon) return;
    const url = BASE_LOG_PATH + "/api/request/debuglog";
    navigator.sendBeacon(url, JSON.stringify(props));
  }

  // api 报错记录
  apiError(data) {
    if (!navigator.sendBeacon) return;
    const url = BASE_LOG_PATH + "/api/request/err";
    navigator.sendBeacon(url, JSON.stringify(data));
  }
}

export default new IMLog();
