

import {TemplateView} from "../../general/TemplateView";
import {AvatarView} from "../../AvatarView.js";
import {spinner} from "../../common.js";

export class ContactTileView extends TemplateView {
  render(t, vm) {
    if (vm.type) {
      return t.div({ className: "head-layer" }, [
        t.div({ className: "category-hss" }, vm.text),
        t.div({ className: "count-num" }, `(${vm.quantity})`),
      ]);
    }
    const classes = {
      active: (vm) => vm.isOpen,
      hidden: (vm) => vm.hidden,
    };

    return t.li({ className: classes }, [
      t.a(
        {
          href:
            vm.roomCategory === "contacts"
              ? vm.userInfoUrl
              : vm.roomCategory === "groups"
              ? vm.groupDetailUrl
              : vm.roomCategory === "ServiceNumber"
              ? vm.userInfoUrl
              : vm.url,
        },
        [
          t.view(new AvatarView(vm, 52, "square"), {
            parentProvidesUpdates: true,
          }),
          t.div({ className: "description contact" }, [
            t.div(
              { className: { name: true, ellipsis:true, unread: (vm) => vm.isUnread } },
              (vm) => vm.name
            ),
            t.div(
              {
                className: "hr",
              },
              ""
            ),
          ]),
        ]
      ),
    ]);
  }

  update(value, props) {
    super.update(value);
    // update the AvatarView as we told it to not subscribe itself with parentProvidesUpdates
    this.updateSubViews(value, props);
  }
}
