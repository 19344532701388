
    import IMLog from "./IMLog";
    IMLog.addEventListenerError();
    
    import { main } from "./main";
    import { Platform } from "./Platform";
    import configURL from "./assets/config.json?url";
    import assetPaths from "./sdk/paths/vite";
    // if (import.meta.env.PROD) {
    //   assetPaths.serviceWorker = "sw.js";
    // }

    const platform = new Platform({
      container: document.body,
      assetPaths,
      configURL,
      options: { development: import.meta.env.DEV }
    });
    main(platform);
    // window.IM_VERSION = import.meta.env.VITE_VERSION;
    window.IS_APP = location.hash.indexOf("/hide-back") !== -1
    window.platform = platform;

    

    // 监听 popstate 事件
    // window.addEventListener('popstate', function (e) {
    //   const { hash = ''} = e.target.location;
    //   // console.log("----event", e.target.location, hash.indexOf("#/thirdparty/"));
    //   if(hash.indexOf("#/thirdparty/") === 0){
    //     window.close();
    //   }
    // });

  