import { TemplateView } from "../general/TemplateView";
import { LoadingView } from "../general/LoadingView";
import { AvatarView } from "../AvatarView";
import { StaticView } from "../general/StaticView";

export class UpdateAvatarView extends TemplateView {

  render(t, vm) {
    return t.main({ className: "update-avatar-layer" }, [
      t.map(
        (vm) => vm.loading,
        (l, t) => {
          if (l) {
            return t.view(new LoadingView(vm.i18n`Loading`));
          }

          return t.div({ className: "content" }, [
            t.div({ className: "header" }, [
              t.a({
                className: "button-utility close-middle",
                href: vm.closeUrl,
              }),
            ]),
            t.div(
              {
                className: "title",
              },
              "修改头像"
            ),

            t.div({ className: "avatar-layer" }, [
              t.button(
                {
                  type: "button",
                  className: "CreateRoomView_selectAvatar",
                  onClick: () => vm.selectAvatar(),
                },
                t.mapView(
                  (vm) => vm.hasAvatar,
                  (hasAvatar) => {
                    if (hasAvatar) {
                      return new AvatarView(vm, 128);
                    } else {
                      return new StaticView(undefined, (t) => {
                        return t.div(
                          {
                            className: `avatar size-128 usercolor${vm.avatarColorNumber}`,
                          },
                          vm.avatarInitials
                        );
                      });
                    }
                  }
                )
              ),
            ]),

           
            t.div(
              { className: "btn" },
              t.button(
                {
                  className: "button-action primary",
                  type: "submit",
                  onClick: () => vm.handleSaveAvatar(),
                },
                vm.i18n`Save`
              )
            ),
            t.map(
              (vm) => vm.error,
              (e, t) => t.p({ className: "error" }, e)
            ),
          ]);
        }
      ),
    ]);
  }
}
