
import { TemplateView } from "../../general/TemplateView";

import { ListView } from "../../general/ListView";
import { RoomTileView } from "./RoomTileView.js";
import { Menu } from "../../general/Menu.js";
import { Popup } from "../../general/Popup.js";




class FilterField extends TemplateView {
  render(t, options) {
    const clear = () => {
      filterInput.value = "";
      filterInput.blur();
      clearButton.blur();
      options.clear();
    };
    const filterInput = t.input({
      type: "text",
      placeholder: options?.label,
      "aria-label": options?.label,
      autocomplete: options?.autocomplete,
      enterkeyhint: "search",
      name: options?.name,
      onInput: (event) => options.set(event.target.value),
      onKeydown: (event) => {
        if (event.key === "Escape" || event.key === "Esc") {
          clear();
        }
      },
      onFocus: () => filterInput.select(),
    });
    const clearButton = t.button({
      onClick: clear,
      title: options.i18n`Clear`,
      "aria-label": options.i18n`Clear`,
    });
    return t.div({ className: "FilterField" }, [filterInput, clearButton]);
  }
}


export class SearchView extends TemplateView {
  constructor(vm) {
    super(vm);
    this._createMenuPopup = null;
  }

  render(t, vm) {



      const head = t.div({ className: "head" }, [
        t.view(
          new FilterField({
            i18n: vm.i18n,
            label: vm.i18n`Filter rooms and people`,
            name: "room-filter",
            autocomplete: true,
            set: (query) => {
              // scroll up if we just started filtering
              if (vm.setFilter(query)) {
                roomList.scrollTop = 0;
              }
            },
            clear: () => vm.clearFilter(),
          })
        ),
        t.a(
          {
            className: "cancel",
            href: vm.closeUrl,
            title: vm.i18n`Cancel`,
          },
          vm.i18n`Cancel`
        ),
      ]);


       const roomList = t.view(
         new ListView(
           {
             className: "RoomList",
             list: vm.tileViewModels,
            //  category: 'message',
             i18n: vm.i18n,
           },
           (tileVM) => new RoomTileView(tileVM)
         )
       );

      const content = t.div({ className: "content search-list" }, [
        // t.div({ className: "null" }, vm.i18n`No matching data`),
        roomList,
      ]);



    return t.div({ className: "search-view" }, [head, content]);
  }
}
